import { GetUserResponse, UserRoles, UsersApiClient } from '@wecore/sdk-core';
import { ExaminationRoom, GetPracticeLocationResponse, PracticeLocationsApiClient } from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isZero } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject } from 'aurelia';
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    format,
    getDaysInMonth,
    getMonth,
    getWeek,
    getYear,
    parse,
    setDayOfYear,
    setMonth,
    setYear,
    startOfDay,
    startOfWeek,
    subMonths,
    subYears
} from 'date-fns';
import { Broadcasters } from '../../infra/broadcasts/broadcasters';
import { Broadcasts } from '../../infra/broadcasts/broadcasts';
import { SchedulerBroadcaster } from '../../infra/broadcasts/scheduler-broadcaster';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { cloneDeep } from '../../infra/utilities';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';

@inject(UsersApiClient, IEventAggregator, PracticeLocationsApiClient, SchedulerBroadcaster, ErrorHandler)
export class BxSchedulerHeader {
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public showToday: boolean = false;
    @bindable() public settings: SchedulerSettings;
    @bindable() public viewWidth: number;
    @bindable() public userSelection: GetUserResponse[];
    @bindable() public wrapperSelection: SchedulerRoomWrapper[];
    @bindable() public authenticatedUser: GetUserResponse;
    @bindable() public onNewAppointment: (start: Date, end: Date, userId: string) => Promise<void>;
    @bindable() public startDate: Date;
    @bindable() public hasRole: (role: UserRoles) => boolean;

    public screenCutOff: number = 1110;
    public users: GetUserResponse[] = [];
    public locations: GetPracticeLocationResponse[] = [];
    public selectedUsers: GetUserResponse[] = [];
    public selectedLocations: GetPracticeLocationResponse[] = [];
    public selectedRooms: ExaminationRoom[] = [];
    public showUsers: boolean = false;
    public showLocations: boolean = false;
    public hasSelectedUsers: boolean = false;
    public hasSelectedLocations: boolean = false;
    public showCalendar: boolean = false;
    public usersFocused: boolean = false;
    public locationsFocused: boolean = false;
    public searchUsersInput: HTMLInputElement;
    public searchLocationsInput: HTMLInputElement;
    public userOptions: HTMLUListElement;
    public locationOptions: HTMLUListElement;
    public focusedUser: number = -1;
    public focusedLocation: number = -1;
    public selectedDate: Date;
    public daysOfPreviousMonth: Date[];
    public daysOfCurrentMonth: Date[];
    public daysOfNextMonth: Date[];
    public rows: Date[][] = [];
    public weeks: number[];
    public labels: string[] = [
        'translation:global.days.short.week', //
        'translation:global.days.short.monday',
        'translation:global.days.short.tuesday',
        'translation:global.days.short.wednesday',
        'translation:global.days.short.thursday',
        'translation:global.days.short.friday',
        'translation:global.days.short.saturday',
        'translation:global.days.short.sunday'
    ];
    public selectedAllUsers: boolean = false;
    public selectedAllLocations: boolean = false;
    public subscriptions: IDisposable[] = [];
    public state: string = Broadcasts.OnReconnecting;
    public UserRoles: typeof UserRoles = UserRoles;

    private clickCb: (e: MouseEvent) => void;
    private keyCb: (e: KeyboardEvent) => void;

    public constructor(
        private readonly usersApi: UsersApiClient, //
        private readonly events: IEventAggregator,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly broadcaster: SchedulerBroadcaster,
        private readonly errorHandler: ErrorHandler
    ) {}

    public bound(): void {
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(Broadcasts.OnConnected, (data: { broadcaster: string; connectionId: string }) => {
                if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                this.state = Broadcasts.OnConnected;
            }),
            this.events.subscribe(Broadcasts.OnDisconnected, (data: { broadcaster: string; state: string; error: string }) => {
                if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                this.state = data.state;
            }),
            this.events.subscribe(Broadcasts.OnReconnected, (data: { broadcaster: string; state: string; error: string }) => {
                if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                this.state = data.state;
            }),
            this.events.subscribe(Broadcasts.OnReconnecting, (data: { broadcaster: string; state: string; error: string }) => {
                if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                this.state = data.state;
            })
        ];
    }

    public async attached(): Promise<void> {
        try {
            this.renderPicker();
            const [users, locations] = await Promise.all([
                this.usersApi.search(this.workspace, '', 50), //
                this.hasRole(UserRoles.ReadPracticeLocations)
                    ? this.locationsApi.search(this.workspace, '', 50, undefined, undefined, undefined, undefined, undefined, true) //
                    : null
            ]);
            this.users = users.data;
            this.locations = locations?.data ?? [];

            if (isDefined(this.userSelection) && this.userSelection.any()) {
                this.selectedUsers = cloneDeep(this.userSelection);
                this.hasSelectedUsers = this.selectedUsers.any();
            }

            if (isDefined(this.wrapperSelection) && this.wrapperSelection.any()) {
                this.selectedLocations = cloneDeep(this.wrapperSelection.map((x) => x.location)).distinct((x) => x.id);
                this.hasSelectedLocations = this.selectedLocations.any();
            }

            this.clickCb = (event: MouseEvent) => {
                const target = event.target as HTMLElement;

                if (this.showUsers) {
                    // Check if it is the combobox.
                    let box = target.closest('[data-role="combobox"]');
                    // Check if its an option.
                    if (isNotDefined(box)) box = target.closest('[data-role="option"]');
                    if (isNotDefined(box)) this.closeUsers();
                }

                if (this.showLocations) {
                    // Check if it is the combobox.
                    let box = target.closest('[data-role="combobox"]');
                    // Check if its an option.
                    if (isNotDefined(box)) box = target.closest('[data-role="option"]');
                    if (isNotDefined(box)) this.closeLocations();
                }

                if (this.showCalendar) {
                    // Check if it is the calendar.
                    const box = target.closest('[data-role="calendar"]');
                    if (isNotDefined(box)) this.closeCalendar();
                }
            };

            this.keyCb = (event: KeyboardEvent) => {
                if (!this.showUsers && !this.showCalendar && event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter' && event.key !== 'Escape') return;

                // Close the user selection box when the 'Escape' key is pressed.
                if (event.key === 'Escape') {
                    if (this.showUsers) this.closeUsers();
                    if (this.showCalendar) this.closeCalendar();
                    if (this.showLocations) this.closeLocations();
                    return;
                }

                if (this.showUsers) {
                    // If the 'Enter' key is pressed, select the focused item
                    if (event.key === 'Enter') {
                        if (this.focusedUser > -1) this.selectUser(this.users[this.focusedUser]);
                    }

                    // If the 'ArrayDown' key is pressed navigate to the previous item.
                    if (event.key === 'ArrowUp') {
                        if (this.focusedUser === 0) {
                            this.focusedUser = this.users.length - 1;
                        } else this.focusedUser--;
                    }

                    // If the ArrowUp key is pressed and the focused index
                    // is not bigger than the amout of results, focus the next item.
                    if (event.key === 'ArrowDown') {
                        if (this.focusedUser === this.users.length - 1) {
                            this.focusedUser = 0;
                        } else this.focusedUser++;
                    }

                    // Allow the .focused class some time to update on the elements.
                    setTimeout(() => {
                        const element = this.userOptions.querySelector('.focused') as HTMLElement;
                        if (isDefined(element)) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    }, 25);
                }

                if (this.showLocations) {
                    // If the 'Enter' key is pressed, select the focused item
                    if (event.key === 'Enter') {
                        if (this.focusedLocation > -1) this.selectLocation(this.locations[this.focusedLocation]);
                    }

                    // If the 'ArrayDown' key is pressed navigate to the previous item.
                    if (event.key === 'ArrowUp') {
                        if (this.focusedLocation === 0) {
                            this.focusedLocation = this.locations.length - 1;
                        } else this.focusedLocation--;
                    }

                    // If the ArrowUp key is pressed and the focused index
                    // is not bigger than the amout of results, focus the next item.
                    if (event.key === 'ArrowDown') {
                        if (this.focusedLocation === this.locations.length - 1) {
                            this.focusedLocation = 0;
                        } else this.focusedLocation++;
                    }

                    // Allow the .focused class some time to update on the elements.
                    setTimeout(() => {
                        const element = this.locationOptions.querySelector('.focused') as HTMLElement;
                        if (isDefined(element)) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    }, 25);
                }
            };

            // Hide user selection box when clicked outside the box.
            document.addEventListener('click', this.clickCb);
            document.addEventListener('keydown', this.keyCb);

            this.selectedAllLocations = this.selectedLocations.length === this.locations.length;
            this.selectedAllUsers = this.selectedUsers.length === this.users.length;
        } catch (e) {
            this.errorHandler.handle('BxSchedulerHeader.attached', e);
        }
    }

    public detaching() {
        this.subscriptions.forEach((x) => x.dispose());
        document.removeEventListener('click', this.clickCb);
        document.removeEventListener('keydown', this.keyCb);
    }

    public handleToToday(): void {
        this.handleDateSelected(new Date());
    }

    public selectUser(user: GetUserResponse): void {
        const index = this.selectedUsers.findIndex((u) => u.id === user.id);
        if (index >= 0) this.selectedUsers.splice(index, 1);
        else this.selectedUsers.push(user);

        this.selectedAllUsers = this.selectedUsers.length === this.users.length;
    }

    public selectLocation(location: GetPracticeLocationResponse): void {
        const index = this.selectedLocations.findIndex((u) => u.id === location.id);
        if (index >= 0) this.selectedLocations.splice(index, 1);
        else this.selectedLocations.push(location);

        this.selectedAllLocations = this.selectedLocations.length === this.locations.length;
        this.selectedRooms = this.selectedLocations.selectMany((x: GetPracticeLocationResponse) => x.rooms).filter((x) => x.isTreatmentRoom);
    }

    public async handleUsersSearch(): Promise<void> {
        const query = this.searchUsersInput.value;
        const [users] = await Promise.all([
            this.usersApi.search(this.workspace, isNotEmpty(query) ? query : '', 50) //
        ]);
        this.users = users.data;
        this.focusedUser = -1;
    }

    public async handleLocationsSearch(): Promise<void> {
        const query = this.searchLocationsInput.value;
        const [locations] = await Promise.all([
            this.locationsApi.search(this.workspace, isNotEmpty(query) ? query : '', 50, undefined, undefined, undefined, undefined, undefined, true) //
        ]);
        this.locations = locations.data;
        this.focusedUser = -1;
    }

    public clearSelectedUsers(): void {
        this.selectedUsers = [];
        this.hasSelectedUsers = false;
        this.selectedAllUsers = false;
        this.events.publish(CustomEvents.SchedulerUsersChanged, []);

        this.selectedRooms = [];
    }

    public clearSelectedLocations(): void {
        this.selectedLocations = [];
        this.hasSelectedLocations = false;
        this.selectedAllLocations = false;
        this.events.publish(CustomEvents.SchedulerLocationsChanged, []);
        this.selectedRooms = [];
    }

    public handleUsersFocus(): void {
        this.usersFocused = true;
    }

    public handleLocationsFocus(): void {
        this.locationsFocused = true;
    }

    public handleUsersBlur(): void {
        this.usersFocused = false;
    }

    public handleLocationsBlur(): void {
        this.locationsFocused = false;
    }

    public openUsers(): void {
        this.showUsers = true;
        this.showLocations = false;
        setTimeout(() => this.searchUsersInput.focus(), 100);
    }

    public openLocations(): void {
        this.showLocations = true;
        this.showUsers = false;
        setTimeout(() => this.searchLocationsInput.focus(), 100);
    }

    public closeUsers(): void {
        this.showUsers = false;
        this.searchUsersInput.value = '';
        this.handleUsersSearch();

        const cloned = cloneDeep(this.selectedUsers).map(GetUserResponse.fromJS);
        this.events.publish(CustomEvents.SchedulerUsersChanged, cloned);
        this.hasSelectedUsers = cloned.any();

        if (this.hasSelectedUsers && this.hasSelectedLocations) {
            this.selectedLocations = [];
            this.hasSelectedLocations = false;
            this.selectedAllLocations = false;
            this.selectedRooms = [];
        }
    }

    public closeLocations(): void {
        this.showLocations = false;
        this.searchLocationsInput.value = '';
        this.handleLocationsSearch();

        const cloned = cloneDeep(this.selectedLocations).map(GetPracticeLocationResponse.fromJS);
        this.events.publish(CustomEvents.SchedulerLocationsChanged, cloned);
        this.hasSelectedLocations = cloned.any();

        if (this.hasSelectedLocations && this.hasSelectedUsers) {
            this.selectedUsers = [];
            this.hasSelectedUsers = false;
            this.selectedAllUsers = false;
        }
    }

    public selectAllUsers(): void {
        if (this.selectedAllUsers) {
            this.selectedUsers = [];
            this.hasSelectedUsers = false;
        } else {
            this.selectedUsers = cloneDeep(this.users);
            this.hasSelectedUsers = true;
        }
        this.selectedAllUsers = !this.selectedAllUsers;
    }

    public selectAllLocations(): void {
        if (this.selectedAllLocations) {
            this.selectedLocations = [];
            this.selectedRooms = [];
            this.hasSelectedLocations = false;
        } else {
            this.selectedLocations = cloneDeep(this.locations);
            this.selectedRooms = this.selectedLocations.selectMany((x: GetPracticeLocationResponse) => x.rooms).filter((x) => x.isTreatmentRoom);
            this.hasSelectedLocations = true;
        }
        this.selectedAllLocations = !this.selectedAllLocations;
    }

    public getBackgroundColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'bg-gray-200 border border-solid border-gray-300';
        if (user.avatar.color === 'white') return `bg-${user.avatar.color}-${user.avatar.shade} border border-solid border-gray-300`;
        return `bg-${user.avatar.color}-${user.avatar.shade}`;
    }

    public getTextColor(user: GetUserResponse): string {
        if (isNotDefined(user.avatar) || isEmpty(user.avatar.color) || isNotDefined(user.avatar.shade) || isZero(user.avatar.shade)) return 'text-gray-700';

        if (user.avatar.shade < 500) return `text-${user.avatar.color}-900`;
        else return 'text-white';
    }

    public getInititals(user: GetUserResponse): string {
        let lastInitial = '';
        let firstInitial = '';

        if (isNotEmpty(user.displayName)) {
            const filtered = user.displayName.replace(/[^a-zA-Z ]/gm, '').trim();
            const displayNameSplit = filtered.split(' ');
            firstInitial = displayNameSplit[0].charAt(0);

            if (displayNameSplit.length > 1) lastInitial = displayNameSplit[displayNameSplit.length - 1].charAt(0);
        }

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }

    public openCalendar(): void {
        this.showCalendar = true;
    }

    public closeCalendar(): void {
        this.showCalendar = false;
    }

    public handleDateSelected(date?: Date): void {
        if (isNotDefined(date)) {
            this.startDate = startOfDay(new Date());
            this.selectedDate = this.startDate;
        } else {
            this.startDate = startOfDay(date);
            this.selectedDate = this.startDate;
        }

        this.renderPicker();
        this.events.publish(CustomEvents.SchedulerDateChanged, this.selectedDate);
        this.closeCalendar();
    }

    public handleNextWeekOrDay(): void {
        if (this.selectedUsers.length > 1 || this.selectedRooms.length > 1) {
            const nextday = addDays(this.startDate, 1);
            this.handleDateSelected(nextday);
        } else {
            // Get the first day of the next week.
            const nextWeek = addWeeks(this.startDate, 1);
            const firstDay = startOfWeek(nextWeek, { weekStartsOn: 1 });
            this.handleDateSelected(firstDay);
        }
    }

    public handlePreviousWeekOrDay(): void {
        if (this.selectedUsers.length > 1 || this.selectedRooms.length > 1) {
            const previousDay = addDays(this.startDate, -1);
            this.handleDateSelected(previousDay);
        } else {
            // Get the first day of the previous week.
            const previousWeek = addWeeks(this.startDate, -1);
            const firstDay = startOfWeek(previousWeek, { weekStartsOn: 1 });
            this.handleDateSelected(firstDay);
        }
    }

    public isToday(day: Date): boolean {
        return format(day, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
    }

    public isSelected(day: Date): boolean {
        if (isNotDefined(this.selectedDate)) return false;
        return format(day, 'yyyyMMdd') === format(this.selectedDate, 'yyyyMMdd');
    }

    public isInCurrentMonth(day: Date): boolean {
        return format(day, 'yyyy-MM') === format(this.startDate, 'yyyy-MM');
    }

    public handleNextYear(): void {
        this.startDate = addYears(this.startDate, 1);
        this.renderPicker();
    }

    public handlePreviousYear(): void {
        this.startDate = subYears(this.startDate, 1);
        this.renderPicker();
    }

    public handleNextMonth(): void {
        this.startDate = addMonths(this.startDate, 1);
        this.renderPicker();
    }

    public handlePreviousMonth(): void {
        this.startDate = subMonths(this.startDate, 1);
        this.renderPicker();
    }

    public handleNewAppointment(): void {
        if (isDefined(this.onNewAppointment)) this.onNewAppointment(null, null, this.selectedUsers.any() ? null : this.authenticatedUser.id);
    }

    private generateRow(rowNumber: number): Date[] {
        const amountOfDaysPerWeek = 7;

        // Check if we need days from the previous month, by checking which
        // day (mo, tue, wed etc.) the first of the current month is. Substract
        // one because we need the amount of days BEFORE the first day of the month.
        const firstDayOfCurrentMonth = parse(`${this.startDate.getFullYear()}-${this.startDate.getMonth() + 1}-1`, 'yyyy-MM-dd', new Date());
        let amountOfDaysToTake = firstDayOfCurrentMonth.getDay() === 0 ? 6 : firstDayOfCurrentMonth.getDay() - 1;

        // Make sure we don't have negative numbers.
        if (amountOfDaysToTake < 0) amountOfDaysToTake = 0;

        if (rowNumber === 0) {
            // Fetch the days we need from the end of the previous month by using the amount of days to take variable.
            const daysOfPreviousMonth = this.daysOfPreviousMonth.slice(this.daysOfPreviousMonth.length - amountOfDaysToTake, this.daysOfPreviousMonth.length);
            // Hoin the days of the previous month we took, with the current month for the first row.
            // Note that the days of the previous month need to come BEFORE the days of the current month.
            return daysOfPreviousMonth.concat(this.daysOfCurrentMonth.slice(0, amountOfDaysPerWeek - daysOfPreviousMonth.length));
        }

        // Because we might have some days of the previous month we could have an offset
        // we need to use when calculating the days for the other rows.
        const offset = 7 - amountOfDaysToTake;

        if (rowNumber === 1)
            // These row days needs to be calculated based on the amount of days
            // we took from the previous month.
            return this.daysOfCurrentMonth.slice(offset, offset + amountOfDaysPerWeek);

        if (rowNumber > 1 && rowNumber < 4) {
            // These rows always contain days from the current month.
            // Calculate which ones we need and add them to the row.
            const amountOfDays = amountOfDaysPerWeek * rowNumber;
            return this.daysOfCurrentMonth.slice(amountOfDays - amountOfDaysToTake, offset + amountOfDays);
        }

        if (rowNumber === 4) {
            const amountOfDays = amountOfDaysPerWeek * rowNumber - amountOfDaysToTake;
            const lastDaysOfCurrentMonth = this.daysOfCurrentMonth.slice(amountOfDays, this.daysOfCurrentMonth.length);

            if (lastDaysOfCurrentMonth.length > amountOfDaysPerWeek)
                // We still have 7 days left of the current month so add them to the fifth row.
                return lastDaysOfCurrentMonth.slice(0, amountOfDaysPerWeek);
            else {
                // We have some days (less than 7) of the current month left to add, so calculate which
                // days we need from the next month and add them
                const firstDaysOfNextMonth = this.daysOfNextMonth.slice(0, amountOfDaysPerWeek - lastDaysOfCurrentMonth.length);
                return lastDaysOfCurrentMonth.concat(firstDaysOfNextMonth);
            }
        }

        if (rowNumber === 5) {
            const amountOfDays = amountOfDaysPerWeek * rowNumber - amountOfDaysToTake;
            const lastDaysOfCurrentMonth = this.daysOfCurrentMonth.slice(amountOfDays, this.daysOfCurrentMonth.length);

            if (lastDaysOfCurrentMonth.length === 0) {
                // We have no days of the current month left. Check if we already used some
                // days of the next month and continue on the last row.
                const amountOfDaysAlreadyTakenFromNextMonth = amountOfDays - this.daysOfCurrentMonth.length;
                return this.daysOfNextMonth.slice(amountOfDaysAlreadyTakenFromNextMonth, amountOfDaysAlreadyTakenFromNextMonth + amountOfDaysPerWeek);
            } else {
                // We have some days of the current month left to add, so calculate which
                // days we need from the next month and add them
                const firstDaysOfNextMonth = this.daysOfNextMonth.slice(0, amountOfDaysPerWeek - lastDaysOfCurrentMonth.length);
                return lastDaysOfCurrentMonth.concat(firstDaysOfNextMonth);
            }
        }
    }

    private setWeeks(): void {
        this.weeks = [
            getWeek(this.rows[0][0]), //
            getWeek(this.rows[1][0]),
            getWeek(this.rows[2][0]),
            getWeek(this.rows[3][0]),
            getWeek(this.rows[4][0]),
            getWeek(this.rows[5][0])
        ];
    }

    private setDaysOfPreviousMonth(): void {
        this.daysOfPreviousMonth = [];
        let month = getMonth(this.startDate);
        let year = getYear(this.startDate);

        // Check if the current month is the first month of the year,
        // if so switch to the last month of the previous year.
        if (month === 0) {
            month = 11;
            year = year - 1;
        } else month = month - 1;

        const previousMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        // setDayOfYear(new Date(), 1).set('month', month).set('year', year);
        const totalDays = getDaysInMonth(previousMonth);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(previousMonth, i), month), year);
            this.daysOfPreviousMonth.push(day);
        }
    }

    private setDaysOfCurrentMonth(): void {
        this.daysOfCurrentMonth = [];
        const month = getMonth(this.startDate);
        const year = getYear(this.startDate);

        const currentMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        const totalDays = getDaysInMonth(this.startDate);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(currentMonth, i), month), year);
            this.daysOfCurrentMonth.push(day);
        }
    }

    private setDaysOfNextMonth(): void {
        this.daysOfNextMonth = [];
        let month = getMonth(this.startDate);
        let year = getYear(this.startDate);

        // Check if the current month is the last of the year,
        // if so swtich to the first month of the next year.
        if (month === 11) {
            month = 0;
            year = year + 1;
        } else month = month + 1;

        const nextMonth = setYear(setMonth(setDayOfYear(new Date(), 1), month), year);
        const totalDays = getDaysInMonth(nextMonth);
        for (let i = 1; i <= totalDays; i++) {
            const day = setYear(setMonth(setDayOfYear(nextMonth, i), month), year);
            this.daysOfNextMonth.push(day);
        }
    }

    private renderPicker(): void {
        this.setDaysOfPreviousMonth();
        this.setDaysOfNextMonth();
        this.setDaysOfCurrentMonth();
        this.rows = [
            this.generateRow(0), //
            this.generateRow(1),
            this.generateRow(2),
            this.generateRow(3),
            this.generateRow(4),
            this.generateRow(5)
        ];

        this.setWeeks();
    }
}
