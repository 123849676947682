import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse, UsersApiClient } from '@wecore/sdk-core';
import { Email } from '@wecore/sdk-crm';
import { GetPatientResponse, InsuranceStatuses, MedicalHistoryItem, MedicalHistoryItemTypes, PatientsApiClient } from '@wecore/sdk-healthcare';
import { InsuranceTypes } from '@wecore/sdk-integrations';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { differenceInYears } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, UsersApiClient, ModalService)
export class PartialPatientsCard extends BasePartialView {
    public patient: GetPatientResponse;
    public expanded: boolean = false;
    public view: 'ClinicalPathways' | 'PatientContactMoments' | 'PatientInsurances' | 'PatientInvoices' | 'PatientAppointments';
    public MedicalHistoryItemTypes: typeof MedicalHistoryItemTypes = MedicalHistoryItemTypes;
    public closeable: boolean = false;
    public updateUrl: boolean = true;
    public headPractitioner: GetUserResponse;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;

    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly usersApi: UsersApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
        this.closeable = view.data.closeable;
        this.view = view.data.view;
        this.updateUrl = view.data.updateUrl ?? true;
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                Promise.all([
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id), //
                    this.loadViewsFromUrl({
                        open: async (view: string, entityId: string) => {
                            if (isNotDefined(view)) {
                                if (isDefined(this.view)) this.open(this.view);
                                return;
                            }
                            if (isNotEmpty(entityId)) this.changeTo(PartialViews[view].with({ id: entityId }), false);
                            else this.changeTo(PartialViews[view].with({ id: this.patientId, closeable: true }), false);

                            this.view = view as any;
                        }
                    })
                ]).then(([patient]) => {
                    this.patient = patient;
                    this.refreshData().then(() => (this.baseLoaded = true));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialPatientsCard.attached', x));

        this.subscriptions.push(
            this.events.subscribe(CustomEvents.PatientUpdated, async () => {
                this.patient = await this.patientsApi.getById(this.patientId, this.authenticated.workspace.id);
                this.refreshData();
            })
        );
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public toggleWidth(): void {
        if (this.expanded)
            this.changeStyle({
                width: `${this.partial.width}px`,
                minWidth: `${this.partial.width}px`
            });
        else
            this.changeStyle({
                width: `${Number(this.partial.width) * 1.5}px`,
                minWidth: `${Number(this.partial.width) * 1.5}px`
            });
        this.expanded = !this.expanded;

        this.scrollTo(this.partial);
    }

    public async edit(): Promise<void> {
        if (this.closeable) await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditPatient.with({ id: this.patient.id }) //
                .whenClosed(async (result: PartialViewResults, patient: GetPatientResponse) => {
                    if (result === PartialViewResults.Ok) {
                        this.patient = patient;
                        await this.refreshData();
                    }
                    if (result === PartialViewResults.Deleted)
                        await super.remove({
                            result: PartialViewResults.Deleted,
                            updateUrl: true
                        });
                }),
            options: new ViewOptions({
                scrollToView: true, //
                markItem: false,
                replace: true,
                updateUrl: this.updateUrl,
                index: this.partial.index + 1
            })
        });
    }

    public async sendEmailTo(email: Email): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SendEmailToPatient.with({
                email, //
                id: this.patient.id
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
        });
    }

    public async open(view: 'ClinicalPathways' | 'PatientContactMoments' | 'PatientInsurances' | 'PatientInvoices' | 'PatientAppointments'): Promise<void> {
        await super.removeChildViews();
        switch (view) {
            case 'ClinicalPathways':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.ClinicalPathways.with({ id: this.patientId, closeable: this.closeable }).whenClosed(async (result: PartialViewResults) => {
                        if (result !== PartialViewResults.Detached && this.closeable) this.view = null;
                    }),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
                });
                break;
            case 'PatientContactMoments':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.PatientContactMoments.with({ id: this.patientId, closeable: this.closeable }).whenClosed(async (result: PartialViewResults) => {
                        if (result !== PartialViewResults.Detached && this.closeable) this.view = null;
                    }),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
                });
                break;
            case 'PatientInsurances':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.PatientInsurances.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {
                        if (result !== PartialViewResults.Detached && this.closeable) this.view = null;
                    }),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
                });
                break;
            case 'PatientInvoices':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.PatientInvoices.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {
                        if (result !== PartialViewResults.Detached && this.closeable) this.view = null;
                    }),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
                });
            case 'PatientAppointments':
                await this.addPartialView({
                    view: this.partial.base, //
                    partial: PartialViews.PatientAppointments.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {
                        if (result !== PartialViewResults.Detached && this.closeable) this.view = null;
                    }),
                    options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
                });
                break;
        }
        this.view = view;
    }

    public async showMedication(medication: string): Promise<void> {
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.MedicationDetails.with({ medication }), //
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true })
        });
    }

    public getHistoryLength(items: MedicalHistoryItem[], type: MedicalHistoryItemTypes): number {
        return items.filter((x: MedicalHistoryItem) => x.type === type).length;
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patients.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.patients.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.patient.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.patientsApi.delete(this.patientId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.patients.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.patients.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.patient.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[patients-card/delete]', e);
                        }
                    }
                }
            })
        );
    }

    public async covCheck(): Promise<void> {
        super.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.PatientCovCheck.with({ id: this.patientId }).whenClosed(async (result: PartialViewResults) => {
                //
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, updateUrl: this.updateUrl })
        });
    }

    public getAge(date: Date): string {
        const age = differenceInYears(new Date(), new Date(date));
        return `${age} ${this.t.tr('translation:global.labels.years-old').toLowerCase()}`;
    }

    private async refreshData(): Promise<void> {
        if (isNotDefined(this.patient.headPractitioner)) return;

        const [headPractitioner] = await Promise.all([
            this.usersApi.getById(this.patient.headPractitioner.id) //
        ]);

        this.headPractitioner = headPractitioner;
    }
}
