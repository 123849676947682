<import from="converters/date" />

<div
    id="appointment-${appointment.id}"
    ref="container"
    contextmenu.trigger="handleContextMenu($event)"
    data-type="appointment"
    css="background-color: ${appointment.type.color.background}; color: ${appointment.type.color.text};"
    class="${rounding} group absolute flex h-full flex-col border-2 border-[#f7fbff] transition duration-300 ease-in-out hover:!z-[9999]">
    <div class="relative flex flex-col flex-1">
        <div class="z absolute left-0 top-0 z-50 hidden h-full w-full items-center justify-center rounded-md bg-white opacity-25 group-[&.loading]:flex">
            <ux-spinner size="xs"></ux-spinner>
        </div>
        <div mousedown.trigger="handleResizeMouseDown($event, 'top')" class="absolute top-0 z-20 w-full h-2 rounded-t-md hover:cursor-ns-resize"></div>
        <div css="padding-top: ${paddingTop}px" ref="content" data-type="content" mousedown.trigger="handleMoveMouseDown($event)" class="z-10 flex-1 px-4 hover:cursor-pointer">
            <p class="text-xs text-gray-800 truncate"><strong>#${ appointment.patient.data.number }</strong> ${ appointment.patient.name }</p>
            <p show.bind="height > 50" data-type="slot" class="text-xs text-gray-800">${ appointment.start | date:'HH:mm' } - ${ appointment.end | date:'HH:mm' }</p>
        </div>
        <div
            if.bind="appointment.noShow"
            class="absolute flex w-full h-full bg-transparent rounded-md"
            style="opacity: 0.14; background: repeating-linear-gradient(-45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
        <div mousedown.trigger="handleResizeMouseDown($event, 'bottom')" class="absolute bottom-0 z-20 w-full h-2 rounded-b-md hover:cursor-ns-resize"></div>
    </div>
    <div data-type="context-menu" class="absolute z-[10000] hidden w-48 rounded-md border border-gray-200 bg-white">
        <ul class="p-1">
            <li if.bind="hasRole(UserRoles.WriteAppointments)" class="flex">
                <a href="javascript:" data-function="edit" class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900" t="translation:global.buttons.edit"></a>
            </li>
            <li if.bind="hasRole(UserRoles.WriteAppointments)" class="flex">
                <a href="javascript:" data-function="cut" class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900" t="translation:global.buttons.cut"></a>
            </li>
            <li if.bind="hasRole(UserRoles.ReadPatients)" class="flex">
                <a
                    href="javascript:"
                    data-function="patient-card"
                    class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                    t="translation:bx.scheduler.buttons.patient-card">
                </a>
            </li>
            <li if.bind="hasRole(UserRoles.WriteAppointments)" class="flex">
                <a
                    href="javascript:"
                    data-function="appointment-confirmation"
                    class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                    t="translation:bx.scheduler.buttons.appointment-confirmation">
                </a>
            </li>
            <li if.bind="hasRole(UserRoles.WriteAppointments) && !appointment.noShow" class="flex">
                <a
                    href="javascript:"
                    data-function="mark-no-show"
                    class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                    t="translation:bx.scheduler.buttons.no-show-mark">
                </a>
            </li>
            <li if.bind="hasRole(UserRoles.WriteAppointments) && appointment.noShow" class="flex">
                <a
                    href="javascript:"
                    data-function="unmark-no-show"
                    class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                    t="translation:bx.scheduler.buttons.no-show-unmark">
                </a>
            </li>
            <li if.bind="hasRole(UserRoles.DeleteAppointments)" class="flex">
                <a href="javascript:" data-function="delete" class="flex-1 px-3 py-2 text-sm text-red-500 rounded-md hover:bg-gray-100 hover:text-red-700" t="translation:global.buttons.delete"></a>
            </li>
        </ul>
    </div>
</div>
