<import from="bx/bx-scheduler/bx-scheduler" />

<template class="relative !flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-1 gap-x-2 overflow-hidden" css.bind="styles">
        <bx-scheduler
            if.bind="baseLoaded"
            authenticated-user.bind="authenticated.user"
            workspace.bind="authenticated.workspace.id"
            language.bind="language"
            view-width.bind="viewWidth"
            scheduler-state.two-way="schedulerState"
            set-view-width.bind="setViewWidth"
            min-view-width.bind="partial.minWidth"
            constrain-view-width.two-way="constrainViewWidth"
            has-role.bind="hasRole"
            on-task-click.bind="handleTaskClick"
            on-new-appointment.bind="handleAppointmentCreate"
            on-appointment-click.bind="handleAppointmentClick"
            on-appointment-create.bind="handleAppointmentCreate"
            on-appointment-edit.bind="handleAppointmentEdit"
            on-appointment-delete.bind="handleAppointmentDelete"
            on-appointment-move.bind="handleAppointmentResizedOrMoved"
            on-appointment-resize.bind="handleAppointmentResizedOrMoved"
            on-patient-card.bind="handlePatientCard"
            on-confirm-appointment.bind="handleConfirmAppointment"
            on-mark-no-show.bind="handleMarkNoShow"
            on-unmark-no-show.bind="handleUnmarkNoShow">
        </bx-scheduler>
        <!-- <div else class="flex items-center justify-between">
            <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.appointments.messages.searching-schedules"></span>
            <span else class="text-sm" t="translation:partial-views.appointments.messages.no-schedule-found"></span>
            <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
        </div> -->
        <div else class="flex flex-1 items-center justify-center">
            <ux-spinner size="xs"></ux-spinner>
        </div>
    </section>
    <div if.bind="partial.canAdjustWidth" mousedown.trigger="handleResizeMouseDown($event)" class="absolute right-0 z-50 h-full w-2 hover:cursor-col-resize"></div>
</template>
