import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';

import { SchedulerSettings } from '@wecore/sdk-core';
import { GetWorkspaceResponse, WorkspacesApiClient } from '@wecore/sdk-management';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { updateSchedulerSettings } from '../../infra/store/actions/scheduler';
import { State } from '../../infra/store/state';
import { defaultSchedulerSettings } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, WorkspacesApiClient, ModalService)
export class PartialManageScheduler extends BasePartialView {
    public workspace: GetWorkspaceResponse;
    public settings: SchedulerSettings;

    public hours: number[] = [...Array(24).keys()];
    public minutes: number[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                // Get full workspace.
                this.workspace = await this.workspacesApi.getById(this.authenticated.workspace.id);
                const settings = this.workspace.extensions.healthcare.schedulerSettings ?? defaultSchedulerSettings();
                this.setMinutes(settings.slotSize);
                this.settings = settings;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageScheduler.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handleSlotsizeChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.settings.start.minute = 0;
        this.settings.end.minute = 0;
        this.setMinutes(Number(e.detail.values.value));
    }

    public async save(): Promise<void> {
        this.isLoading = true;
        try {
            this.workspace.extensions.healthcare.schedulerSettings = this.settings;
            await this.workspacesApi.update(this.workspace.id, this.workspace);
            await this.store.dispatch(updateSchedulerSettings, this.settings);
            await this.refreshAuthenticated();
            this.events.publish(CustomEvents.ScheduleSettingsChanged);
            this.notifications.show(
                this.t.tr('translation:partial-views.manage-scheduler.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.manage-scheduler.notifications.save-successful.message'),
                {
                    type: 'success',
                    duration: 3000
                }
            );
        } catch (e) {
            await this.errorHandler.handle('[save-scheduler-settings]', e);
        }
        this.isLoading = false;
    }

    private setMinutes(slotSize: number): void {
        switch (Number(slotSize)) {
            case 5:
                this.minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
                break;
            case 10:
                this.minutes = [0, 10, 20, 30, 40, 50];
                break;
            case 15:
                this.minutes = [0, 15, 30, 45];
                break;
            case 30:
                this.minutes = [0, 30];
                break;
        }
    }
}
