import { Store, connectTo } from '@aurelia/store-v1';
import { GetUserResponse, UserRoles, UsersApiClient } from '@wecore/sdk-core';
import { ExaminationRoom, GetAppointmentResponse, GetHealthcareTaskResponse, GetPracticeLocationResponse, PracticeLocationsApiClient } from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { IPageState, PageState, getPageState, isDefined, savePageState } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject } from 'aurelia';
import { addDays, format, startOfDay, startOfWeek } from 'date-fns';
import { CustomEvents } from '../../infra/events';
import { State } from '../../infra/store/state';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';
import { SchedulerState } from '../../models/scheduler-state';
import { BxSchedulerColumn } from '../bx-scheduler-column/bx-scheduler-column';
import { BxSchedulerHeaderColumn } from '../bx-scheduler-header-column/bx-scheduler-header-column';

@inject(Store<State>, IEventAggregator, UsersApiClient, PracticeLocationsApiClient)
@connectTo<State>()
export class BxScheduler {
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public authenticatedUser: GetUserResponse;
    @bindable() public startDate: Date;
    @bindable() public viewWidth: number;
    @bindable() public minViewWidth: number;
    @bindable() public schedulerState: SchedulerState;
    @bindable() public setViewWidth: (setToOriginal: boolean, width?: number) => void;
    @bindable() public constrainViewWidth: (width: number) => number;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onNewAppointment: (start: Date, end: Date, userId: string) => Promise<void>;
    @bindable() public onTaskClick: (task: GetHealthcareTaskResponse) => Promise<GetHealthcareTaskResponse>;
    @bindable() public onAppointmentClick: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentCreate: (start: Date, end: Date, userId: string, roomId: string, locationId: string) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentEdit: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentDelete: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onAppointmentMove: (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentResize: (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onPatientCard: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onConfirmAppointment: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkNoShow: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkNoShow: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;

    public state: State;
    public periods: SchedulerPeriod[] = [];
    public columns: BxSchedulerColumn[] = [];
    public headers: BxSchedulerHeaderColumn[] = [];
    public showToday: boolean = false;
    public baseLoaded: boolean = false;
    public settings: SchedulerSettings;
    public users: GetUserResponse[] = [];
    public wrappers: SchedulerRoomWrapper[] = [];
    public week: { [key: string]: Date } = {};
    public scrollContainer: HTMLDivElement;
    public scrollPositionY: number = 0;
    public scrollPositionX: number = 0;
    public UserRoles: typeof UserRoles = UserRoles;

    private keyEventCb: (e: KeyboardEvent) => void;
    private clickEventCb: (e: MouseEvent) => void;
    private subscriptions: IDisposable[] = [];
    private pageState: IPageState;

    public constructor(
        public readonly store: Store<State>, //
        private readonly events: IEventAggregator,
        private readonly usersApi: UsersApiClient,
        private readonly locationsApi: PracticeLocationsApiClient
    ) {}

    public async bound(): Promise<void> {
        this.settings = this.state.schedulerSettings;
        this.pageState =
            getPageState('bx-scheduler') ??
            new PageState({
                name: 'bx-scheduler',
                values: {
                    expandedTasks: true,
                    users: [],
                    locations: [],
                    startDate: this.startDate ?? startOfDay(new Date())
                }
            });
        savePageState(this.pageState);

        this.startDate = new Date(this.pageState.values.startDate);

        if (this.pageState.values.users?.any() ?? false) {
            const response = await this.usersApi.search(this.workspace, undefined, 100, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.pageState.values.users);
            this.users = response.data;
        }

        if (this.pageState.values.wrappers?.any() ?? false) {
            const response = await this.locationsApi.search(this.workspace, undefined, 100, 0, undefined, undefined, undefined, this.pageState.values.wrappers);
            this.wrappers = response.data
                .selectMany((location: GetPracticeLocationResponse) =>
                    location.rooms
                        .filter((r) => r.isTreatmentRoom) //
                        .map((room: ExaminationRoom) => new SchedulerRoomWrapper({ location, room }))
                )
                // Sort the wrappers by location name and then by room name.
                .sort((a: SchedulerRoomWrapper, b: SchedulerRoomWrapper) => {
                    const nameA = isDefined(a.location.applicationName) ? a.location.applicationName[this.language] : a.location.name[this.language];
                    const nameB = isDefined(b.location.applicationName) ? b.location.applicationName[this.language] : b.location.name[this.language];

                    // Compare location names first
                    var locationComp = nameA.localeCompare(nameB);
                    // If the locations are the same, compare room name
                    if (locationComp === 0) return a.room.name[this.language].localeCompare(b.room.name[this.language]);
                    return locationComp;
                });
        }

        // Render periods based on the start and end hour from the scheduler settings.
        for (let hour = this.settings.start.hour; hour <= this.settings.end.hour; hour++) {
            // Only add the last hour if the end minute is not 0.
            if (hour === this.settings.end.hour && this.settings.end.minute === 0) continue;
            this.periods.push(new SchedulerPeriod({ hour: hour, display: `${hour.toString().padStart(2, '0')}:00` }));
        }

        this.render();

        const removeContextMenus = (e: Event) => {
            const target = e.target as HTMLElement;
            const loadingAppointment = document.querySelector('.loading[data-type="appointment"]');
            if (
                // When we have a appointment that has a loading state.
                isDefined(loadingAppointment) &&
                // When we have a target WITHOUT a data-function attribute.
                isDefined(target) &&
                !target.hasAttribute('data-function')
            )
                // Remove the loading class.
                loadingAppointment.classList.remove('loading');

            const clones = document.querySelectorAll('[data-type="context-menu-clone"]');
            clones.forEach((c) => c.remove());
        };

        this.keyEventCb = (e: KeyboardEvent) => {
            if (e.key === 'Escape') removeContextMenus(e);
        };
        this.clickEventCb = (e: MouseEvent) => removeContextMenus(e);

        document.addEventListener('keydown', this.keyEventCb);
        document.addEventListener('mousedown', this.clickEventCb);

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerDateChanged, (date: Date) => {
                this.startDate = date;
                this.resetSchedulerState();
                this.pageState.values.startDate = date;
                savePageState(this.pageState);
                this.render();
            }),
            this.events.subscribe(CustomEvents.SchedulerUsersChanged, (users: GetUserResponse[]) => {
                if (users.any()) {
                    this.wrappers = [];
                    this.pageState.values.wrappers = [];
                }

                this.users = users.sort((a: GetUserResponse, b: GetUserResponse) => {
                    // Compare last names first
                    var lastNameComparison = a.lastName.localeCompare(b.lastName);
                    // If the last names are the same, compare first names
                    if (lastNameComparison === 0) return a.firstName.localeCompare(b.firstName);
                    return lastNameComparison;
                });
                this.resetSchedulerState();
                this.pageState.values.users = users.map((x) => x.id);
                savePageState(this.pageState);
                this.render();
            }),
            this.events.subscribe(CustomEvents.SchedulerLocationsChanged, (locations: GetPracticeLocationResponse[]) => {
                if (locations.any()) {
                    this.users = [];
                    this.pageState.values.users = [];
                }

                const wrappers = locations
                    //  Select all the rooms that are treatment rooms and flatten the array.
                    .selectMany((location: GetPracticeLocationResponse) =>
                        location.rooms
                            .filter((r) => r.isTreatmentRoom) //
                            .map((room: ExaminationRoom) => new SchedulerRoomWrapper({ location, room }))
                    )
                    // Sort the wrappers by location name and then by room name.
                    .sort((a: SchedulerRoomWrapper, b: SchedulerRoomWrapper) => {
                        const nameA = isDefined(a.location.applicationName) ? a.location.applicationName[this.language] : a.location.name[this.language];
                        const nameB = isDefined(b.location.applicationName) ? b.location.applicationName[this.language] : b.location.name[this.language];

                        // Compare location names first
                        var locationComp = nameA.localeCompare(nameB);
                        // If the locations are the same, compare room name
                        if (locationComp === 0) return a.room.name[this.language].localeCompare(b.room.name[this.language]);
                        return locationComp;
                    });

                this.wrappers = wrappers;
                this.resetSchedulerState();
                this.pageState.values.wrappers = wrappers.map((x) => x.location.id);
                savePageState(this.pageState);
                this.render();
            })
        ];

        this.baseLoaded = true;
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
        document.removeEventListener('keydown', this.keyEventCb);
        document.removeEventListener('mousedown', this.clickEventCb);
    }

    public handleScroll(_: Event): void {
        this.scrollPositionY = this.scrollContainer.scrollTop;
        this.scrollPositionX = this.scrollContainer.scrollLeft;
    }

    public handleNewAppointment = (start: Date, end: Date, userId: string): void => {
        this.onNewAppointment(start, end, userId);
    };

    public handleAppointmentClick = (appointment: GetAppointmentResponse): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return this.onAppointmentClick(appointment);
    };

    public handleAppointmentCreate = async (start: Date, end: Date, userId: string, roomId: string, locationId: string): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        const response = await this.onAppointmentCreate(start, end, userId, roomId, locationId);
        return response;
    };

    public handleAppointmentEdit = (appointment: GetAppointmentResponse): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return this.onAppointmentEdit(appointment);
    };

    public handleAppointmentDelete = (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return this.onAppointmentDelete(appointment);
    };

    public handleAppointmentMove = (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return this.onAppointmentMove(appointment, newStart, newEnd);
    };

    public handleAppointmentResize = (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date): Promise<{ success: boolean; appointment: GetAppointmentResponse }> => {
        return this.onAppointmentResize(appointment, newStart, newEnd);
    };

    public handlePatientCard = (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return this.onPatientCard(appointment);
    };

    public handleConfirmAppointment = (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return this.onConfirmAppointment(appointment);
    };

    public handleMarkNoShow = (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return this.onMarkNoShow(appointment);
    };

    public handleUnmarkNoShow = (appointment: GetAppointmentResponse): Promise<{ success: boolean }> => {
        return this.onUnmarkNoShow(appointment);
    };

    private render(): void {
        // Render the week based on the provided start date.
        // First get the monday of the week.
        const firstDay = startOfWeek(this.startDate, { weekStartsOn: 1 });

        // Then render the week.
        for (let i = 0; i < 7; i++) this.week[i] = startOfDay(addDays(firstDay, i));

        const allDays = Object.entries(this.week).map((item) => item[1]);
        this.showToday =
            isDefined(this.users) && this.users.any() //
                ? format(this.startDate, 'yyyyMMdd') !== format(new Date(), 'yyyyMMdd')
                : allDays.every((x) => format(x, 'yyyyMMdd') !== format(new Date(), 'yyyyMMdd'));

        this.columns.forEach((x) => {
            if (isDefined(x)) x.refresh();
        });

        this.headers.forEach((x) => {
            if (isDefined(x)) x.refresh();
        });
    }

    private resetSchedulerState(): void {
        this.schedulerState.isAppointmentDragging = false;
        this.schedulerState.isAppointmentResizing = false;
        this.schedulerState.isColumnDragging = false;
    }
}
