import { PartialView } from '../models/partial-view';
import { PartialAccounts } from '../partials/partial-accounts/partial-accounts';
import { PartialAccountsCard } from '../partials/partial-accounts/partial-accounts-card/partial-accounts-card';
import { PartialAccountsCreate } from '../partials/partial-accounts/partial-accounts-create/partial-accounts-create';
import { PartialAccountsEdit } from '../partials/partial-accounts/partial-accounts-edit/partial-accounts-edit';
import { PartialAddMedicalActions } from '../partials/partial-add-medical-actions/partial-add-medical-actions';
import { PartialAddTreatmentProtocols } from '../partials/partial-add-treatment-protocols/partial-add-treatment-protocols';
import { PartialAddTreatmentTherapies } from '../partials/partial-add-treatment-therapies/partial-add-treatment-therapies';
import { PartialAnatomicalRegions } from '../partials/partial-anatomical-regions/partial-anatomical-regions';
import { PartialAnatomicalRegionsCreate } from '../partials/partial-anatomical-regions/partial-anatomical-regions-create/partial-anatomical-regions-create';
import { PartialAnatomicalRegionsEdit } from '../partials/partial-anatomical-regions/partial-anatomical-regions-edit/partial-anatomical-regions-edit';
import { PartialAppointmentsCreate } from '../partials/partial-appointments-create/partial-appointments-create';
import { PartialAppointmentsEdit } from '../partials/partial-appointments-edit/partial-appointments-edit';
import { PartialClinicalPathways } from '../partials/partial-clinical-pathways/partial-clinical-pathways';
import { PartialClinicalPathwaysDetails } from '../partials/partial-clinical-pathways/partial-clinical-pathways-details/partial-clinical-pathways-details';
import { PartialMedicalRecordsQueue } from '../partials/partial-clinical-pathways/partial-medical-records-queue/partial-medical-records-queue';
import { PartialProSoftwareMedicalRecord } from '../partials/partial-clinical-pathways/partial-prosoftware-medical-record/partial-prosoftware-medical-record';
import { PartialProSoftwareReport } from '../partials/partial-clinical-pathways/partial-prosoftware-report/partial-prosoftware-report';
import { PartialConnectTherapies } from '../partials/partial-connect-therapies/partial-connect-therapies';
import { PartialContacts } from '../partials/partial-contacts/partial-contacts';
import { PartialContactsCard } from '../partials/partial-contacts/partial-contacts-card/partial-contacts-card';
import { PartialContactsCreate } from '../partials/partial-contacts/partial-contacts-create/partial-contacts-create';
import { PartialContactsEdit } from '../partials/partial-contacts/partial-contacts-edit/partial-contacts-edit';
import { PartialDeclarationPerformances } from '../partials/partial-declaration-performances/partial-declaration-performances';
import { PartialDeclarationPerformancesCreate } from '../partials/partial-declaration-performances/partial-declaration-performances-create/partial-declaration-performances-create';
import { PartialDeclarationPerformancesEdit } from '../partials/partial-declaration-performances/partial-declaration-performances-edit/partial-declaration-performances-edit';
import { PartialDeclarations } from '../partials/partial-declarations/partial-declarations';
import { PartialDeclarationsEdit } from '../partials/partial-declarations/partial-declarations-edit/partial-declarations-edit';
import { PartialDeclarationsLogs } from '../partials/partial-declarations/partial-declarations-logs/partial-declarations-logs';
import { PartialDeclarationsProcess } from '../partials/partial-declarations/partial-declarations-process/partial-declarations-process';
import { PartialDifferentialDiagnoses } from '../partials/partial-differential-diagnoses/partial-differential-diagnoses';
import { PartialDifferentialDiagnosesCreate } from '../partials/partial-differential-diagnoses/partial-differential-diagnoses-create/partial-differential-diagnoses-create';
import { PartialDifferentialDiagnosesEdit } from '../partials/partial-differential-diagnoses/partial-differential-diagnoses-edit/partial-differential-diagnoses-edit';
import { PartialDifferentialDiagnosisCategories } from '../partials/partial-differential-diagnosis-categories/partial-differential-diagnosis-categories';
import { PartialDifferentialDiagnosisCategoriesCreate } from '../partials/partial-differential-diagnosis-categories/partial-differential-diagnosis-categories-create/partial-differential-diagnosis-categories-create';
import { PartialDifferentialDiagnosisCategoriesEdit } from '../partials/partial-differential-diagnosis-categories/partial-differential-diagnosis-categories-edit/partial-differential-diagnosis-categories-edit';
import { PartialDocumentsPreview } from '../partials/partial-documents-preview/partial-documents-preview';
import { PartialEditMedicalItem } from '../partials/partial-edit-medical-item/partial-edit-medical-item';
import { PartialEntityContactMoments } from '../partials/partial-entity-contact-moments/partial-entity-contact-moments';
import { PartialEntityContactMomentsDetails } from '../partials/partial-entity-contact-moments/partial-entity-contact-moments-details/partial-entity-contact-moments-details';
import { PartialFilters } from '../partials/partial-filters/partial-filters';
import { PartialHealthcareCodes } from '../partials/partial-healthcare-codes/partial-healthcare-codes';
import { PartialHealthcareInvoices } from '../partials/partial-healthcare-invoices/partial-healthcare-invoices';
import { PartialHealthcareInvoicesEdit } from '../partials/partial-healthcare-invoices/partial-healthcare-invoices-edit/partial-healthcare-invoices-edit';
import { PartialHealthcareInvoicesLineSelection } from '../partials/partial-healthcare-invoices/partial-healthcare-invoices-line-selection/partial-healthcare-invoices-line-selection';
import { PartialHealthcareInvoicesPreview } from '../partials/partial-healthcare-invoices/partial-healthcare-invoices-preview/partial-healthcare-invoices-preview';
import { PartialInformationSheet } from '../partials/partial-information-sheet/partial-information-sheet';
import { PartialIntegrationsVecozo } from '../partials/partial-integrations-vecozo/partial-integrations-vecozo';
import { PartialIntegrationsZorgdomein } from '../partials/partial-integrations-zorgdomein/partial-integrations-zorgdomein';
import { PartialIntegrationsZorgmail } from '../partials/partial-integrations-zorgmail/partial-integrations-zorgmail';
import { PartialManageAppointmentTypes } from '../partials/partial-manage-appointment-types/partial-manage-appointment-types';
import { PartialManageAppointmentTypesCreate } from '../partials/partial-manage-appointment-types/partial-manage-appointment-types-create/partial-manage-appointment-types-create';
import { PartialManageAppointmentTypesEdit } from '../partials/partial-manage-appointment-types/partial-manage-appointment-types-edit/partial-manage-appointment-types-edit';
import { PartialManageEmailTemplates } from '../partials/partial-manage-email-templates/partial-manage-email-templates';
import { PartialManageEmailTemplatesCreate } from '../partials/partial-manage-email-templates/partial-manage-email-templates-create/partial-manage-email-templates-create';
import { PartialManageEmailTemplatesEdit } from '../partials/partial-manage-email-templates/partial-manage-email-templates-edit/partial-manage-email-templates-edit';
import { PartialManageHealthcarePrices } from '../partials/partial-manage-healthcare-prices/partial-manage-healthcare-prices';
import { PartialManageInsurers } from '../partials/partial-manage-insurers/partial-manage-insurers';
import { PartialManageInsurersCreate } from '../partials/partial-manage-insurers/partial-manage-insurers-create/partial-manage-insurers-create';
import { PartialManageInsurersEdit } from '../partials/partial-manage-insurers/partial-manage-insurers-edit/partial-manage-insurers-edit';
import { PartialManagePracticeLocations } from '../partials/partial-manage-practice-locations/partial-manage-practice-locations';
import { PartialManagePracticeLocationsCreate } from '../partials/partial-manage-practice-locations/partial-manage-practice-locations-create/partial-manage-practice-locations-create';
import { PartialManagePracticeLocationsEdit } from '../partials/partial-manage-practice-locations/partial-manage-practice-locations-edit/partial-manage-practice-locations-edit';
import { PartialManagePracticeLocationsRoom } from '../partials/partial-manage-practice-locations/partial-manage-practice-locations-room/partial-manage-practice-locations-room';
import { PartialManagePracticePrices } from '../partials/partial-manage-practice-prices/partial-manage-practice-prices';
import { PartialManagePractice } from '../partials/partial-manage-practice/partial-manage-practice';
import { PartialManageScheduler } from '../partials/partial-manage-scheduler/partial-manage-scheduler';
import { PartialManageSchedules } from '../partials/partial-manage-schedules/partial-manage-schedules';
import { PartialManageSchedulesCreate } from '../partials/partial-manage-schedules/partial-manage-schedules-create/partial-manage-schedules-create';
import { PartialManageSchedulesEdit } from '../partials/partial-manage-schedules/partial-manage-schedules-edit/partial-manage-schedules-edit';
import { PartialManageSchedulesTimeslot } from '../partials/partial-manage-schedules/partial-manage-schedules-timeslot/partial-manage-schedules-timeslot';
import { PartialManageTeams } from '../partials/partial-manage-teams/partial-manage-teams';
import { PartialManageTeamsCreate } from '../partials/partial-manage-teams/partial-manage-teams-create/partial-manage-teams-create';
import { PartialManageTeamsEdit } from '../partials/partial-manage-teams/partial-manage-teams-edit/partial-manage-teams-edit';
import { PartialManageInvitationsCreate } from '../partials/partial-manage-users/partial-manage-invitations-create/partial-manage-invitations-create';
import { PartialManageInvitationsEdit } from '../partials/partial-manage-users/partial-manage-invitations-edit/partial-manage-invitations-edit';
import { PartialManageUsers } from '../partials/partial-manage-users/partial-manage-users';
import { PartialManageUsersEdit } from '../partials/partial-manage-users/partial-manage-users-edit/partial-manage-users-edit';
import { PartialManageVatCategories } from '../partials/partial-manage-vat-categories/partial-manage-vat-categories';
import { PartialManageVatCategoriesCreate } from '../partials/partial-manage-vat-categories/partial-manage-vat-categories-create/partial-manage-vat-categories-create';
import { PartialManageVatCategoriesEdit } from '../partials/partial-manage-vat-categories/partial-manage-vat-categories-edit/partial-manage-vat-categories-edit';
import { PartialMasterData } from '../partials/partial-master-data/partial-master-data';
import { PartialMasterDataFields } from '../partials/partial-master-data/partial-master-data-fields/partial-master-data-fields';
import { PartialMasterDataManage } from '../partials/partial-master-data/partial-master-data-manage/partial-master-data-manage';
import { PartialMedicalExaminationActionCategories } from '../partials/partial-medical-examination-action-categories/partial-medical-examination-action-categories';
import { PartialMedicalExaminationActionCategoriesCreate } from '../partials/partial-medical-examination-action-categories/partial-medical-examination-action-categories-create/partial-medical-examination-action-categories-create';
import { PartialMedicalExaminationActionCategoriesEdit } from '../partials/partial-medical-examination-action-categories/partial-medical-examination-action-categories-edit/partial-medical-examination-action-categories-edit';
import { PartialMedicalExaminationActions } from '../partials/partial-medical-examination-actions/partial-medical-examination-actions';
import { PartialMedicalExaminationActionsCreate } from '../partials/partial-medical-examination-actions/partial-medical-examination-actions-create/partial-medical-examination-actions-create';
import { PartialMedicalExaminationActionsEdit } from '../partials/partial-medical-examination-actions/partial-medical-examination-actions-edit/partial-medical-examination-actions-edit';
import { PartialMedicalExaminationModels } from '../partials/partial-medical-examination-models/partial-medical-examination-models';
import { PartialMedicalExaminationModelsCreate } from '../partials/partial-medical-examination-models/partial-medical-examination-models-create/partial-medical-examination-models-create';
import { PartialMedicalExaminationModelsEdit } from '../partials/partial-medical-examination-models/partial-medical-examination-models-edit/partial-medical-examination-models-edit';
import { PartialMedicalExaminationPhases } from '../partials/partial-medical-examination-phases/partial-medical-examination-phases';
import { PartialMedicalExaminationPhasesCreate } from '../partials/partial-medical-examination-phases/partial-medical-examination-phases-create/partial-medical-examination-phases-create';
import { PartialMedicalExaminationPhasesEdit } from '../partials/partial-medical-examination-phases/partial-medical-examination-phases-edit/partial-medical-examination-phases-edit';
import { PartialMedicalExaminations } from '../partials/partial-medical-examinations/partial-medical-examinations';
import { PartialMedicalExaminationsCreate } from '../partials/partial-medical-examinations/partial-medical-examinations-create/partial-medical-examinations-create';
import { PartialMedicalExaminationsEdit } from '../partials/partial-medical-examinations/partial-medical-examinations-edit/partial-medical-examinations-edit';
import { PartialMedicalQuestionCategories } from '../partials/partial-medical-question-categories/partial-medical-question-categories';
import { PartialMedicalQuestionCategoriesCreate } from '../partials/partial-medical-question-categories/partial-medical-question-categories-create/partial-medical-question-categories-create';
import { PartialMedicalQuestionCategoriesEdit } from '../partials/partial-medical-question-categories/partial-medical-question-categories-edit/partial-medical-question-categories-edit';
import { PartialMedicalQuestionnaires } from '../partials/partial-medical-questionnaires/partial-medical-questionnaires';
import { PartialMedicalQuestionnairesCreate } from '../partials/partial-medical-questionnaires/partial-medical-questionnaires-create/partial-medical-questionnaires-create';
import { PartialMedicalQuestionnairesEdit } from '../partials/partial-medical-questionnaires/partial-medical-questionnaires-edit/partial-medical-questionnaires-edit';
import { PartialMedicalQuestions } from '../partials/partial-medical-questions/partial-medical-questions';
import { PartialMedicalQuestionsCreate } from '../partials/partial-medical-questions/partial-medical-questions-create/partial-medical-questions-create';
import { PartialMedicalQuestionsEdit } from '../partials/partial-medical-questions/partial-medical-questions-edit/partial-medical-questions-edit';
import { PartialMedicalStepSettings } from '../partials/partial-medical-step-settings/partial-medical-step-settings';
import { PartialMedicalSuggestions } from '../partials/partial-medical-suggestions/partial-medical-suggestions';
import { PartialMedicalTherapies } from '../partials/partial-medical-therapies/partial-medical-therapies';
import { PartialMedicalTherapiesCreate } from '../partials/partial-medical-therapies/partial-medical-therapies-create/partial-medical-therapies-create';
import { PartialMedicalTherapiesEdit } from '../partials/partial-medical-therapies/partial-medical-therapies-edit/partial-medical-therapies-edit';
import { PartialMedicalTreatmentProtocols } from '../partials/partial-medical-treatment-protocols/partial-medical-treatment-protocols';
import { PartialMedicalTreatmentProtocolsCreate } from '../partials/partial-medical-treatment-protocols/partial-medical-treatment-protocols-create/partial-medical-treatment-protocols-create';
import { PartialMedicalTreatmentProtocolsDefaultValues } from '../partials/partial-medical-treatment-protocols/partial-medical-treatment-protocols-default-values/partial-medical-treatment-protocols-default-values';
import { PartialMedicalTreatmentProtocolsEdit } from '../partials/partial-medical-treatment-protocols/partial-medical-treatment-protocols-edit/partial-medical-treatment-protocols-edit';
import { PartialMedicationDetails } from '../partials/partial-medication-details/partial-medication-details';
import { PartialPatientAppointments } from '../partials/partial-patient-appointments/partial-patient-appointments';
import { PartialPatientContactMoments } from '../partials/partial-patient-contact-moments/partial-patient-contact-moments';
import { PartialPatientContactMomentsDetails } from '../partials/partial-patient-contact-moments/partial-patient-contact-moments-details/partial-patient-contact-moments-details';
import { PartialPatientCovCheck } from '../partials/partial-patient-cov-check/partial-patient-cov-check';
import { PartialPatientInsurances } from '../partials/partial-patient-insurances/partial-patient-insurances';
import { PartialPatientInvoices } from '../partials/partial-patient-invoices/partial-patient-invoices';
import { PartialPatients } from '../partials/partial-patients/partial-patients';
import { PartialPatientsCard } from '../partials/partial-patients/partial-patients-card/partial-patients-card';
import { PartialPatientsCreate } from '../partials/partial-patients/partial-patients-create/partial-patients-create';
import { PartialPatientsEdit } from '../partials/partial-patients/partial-patients-edit/partial-patients-edit';
import { PartialPracticeIntegrations } from '../partials/partial-practice-integrations/partial-practice-integrations';
import { PartialPracticeSettings } from '../partials/partial-practice-settings/partial-practice-settings';
import { PartialPracticeTemplates } from '../partials/partial-practice-templates/partial-practice-templates';
import { PartialScheduler } from '../partials/partial-scheduler/partial-scheduler';
import { PartialSendEmailToEntity } from '../partials/partial-send-email-to-entity/partial-send-email-to-entity';
import { PartialSendEmailToPatient } from '../partials/partial-send-email-to-patient/partial-send-email-to-patient';
import { PartialSignDocument } from '../partials/partial-sign-document/partial-sign-document';
import { PartialTaskLabelsCreate } from '../partials/partial-tasks/partial-task-labels-create/partial-task-labels-create';
import { PartialTaskLabelsEdit } from '../partials/partial-tasks/partial-task-labels-edit/partial-task-labels-edit';
import { PartialTaskLabels } from '../partials/partial-tasks/partial-task-labels/partial-task-labels';
import { PartialTaskListsCreate } from '../partials/partial-tasks/partial-task-lists-create/partial-task-lists-create';
import { PartialTaskListsEdit } from '../partials/partial-tasks/partial-task-lists-edit/partial-task-lists-edit';
import { PartialTasks } from '../partials/partial-tasks/partial-tasks';
import { PartialTasksCreate } from '../partials/partial-tasks/partial-tasks-create/partial-tasks-create';
import { PartialTasksEdit } from '../partials/partial-tasks/partial-tasks-edit/partial-tasks-edit';
import { PartialTranslations } from '../partials/partial-translations/partial-translations';
import { PartialUploadFiles } from '../partials/partial-upload-files/partial-upload-files';
import { PartialUserPosts } from '../partials/partial-user-posts/partial-user-posts';
import { PartialUserSettings } from '../partials/partial-user-settings/partial-user-settings';

export class PartialViews {
    public static Patients: PartialView = new PartialView({
        name: 'patients',
        component: PartialPatients,
        displayName: 'partial-views.patients.list.title',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static PatientCard: PartialView = new PartialView({
        name: 'patient-card',
        component: PartialPatientsCard,
        parents: ['patients'],
        route: ':id/card',
        displayName: 'partial-views.patients.card.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: true
    });
    public static CreatePatient: PartialView = new PartialView({
        name: 'patients-create',
        component: PartialPatientsCreate,
        parents: ['patients'],
        displayName: 'partial-views.patients.create.title',
        route: 'create',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static EditPatient: PartialView = new PartialView({
        name: 'patients-edit',
        component: PartialPatientsEdit,
        parents: ['patients', 'patient-card'],
        displayName: 'partial-views.patients.edit.title',
        route: ':id/edit',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static PatientContactMoments: PartialView = new PartialView({
        name: 'patient-contact-moments',
        component: PartialPatientContactMoments,
        parents: ['patient-card'],
        route: 'contact-moments',
        displayName: 'partial-views.patient-contact-moments.list.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static PatientContactMomentsDetails: PartialView = new PartialView({
        name: 'patient-contact-moments-details',
        component: PartialPatientContactMomentsDetails,
        parents: ['patient-contact-moments'],
        route: ':id/details',
        displayName: 'partial-views.patient-contact-moments.details.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static ClinicalPathways: PartialView = new PartialView({
        name: 'clinical-pathways',
        component: PartialClinicalPathways,
        parents: ['patient-card'],
        route: 'clinical-pathways',
        displayName: 'partial-views.clinical-pathways.list.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static ClinicalPathwaysDetails: PartialView = new PartialView({
        name: 'clinical-pathways-details',
        component: PartialClinicalPathwaysDetails,
        parents: ['clinical-pathways'],
        displayName: 'partial-views.clinical-pathways.details.title',
        route: ':id/details',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static MedicalRecordQueue: PartialView = new PartialView({
        name: 'medical-records-queue',
        component: PartialMedicalRecordsQueue,
        displayName: 'translation:partial-views.clinical-pathways.queue.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static ProSoftwareMedicalRecord: PartialView = new PartialView({
        name: 'prosoftware-record',
        component: PartialProSoftwareMedicalRecord,
        parents: ['clinical-pathways'],
        route: ':id/details',
        displayName: 'translation:partial-views.clinical-pathways.labels.prosoftware-record',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static ProSoftwareReport: PartialView = new PartialView({
        name: 'prosoftware-report',
        component: PartialProSoftwareReport,
        parents: ['clinical-pathways'],
        route: ':id/details',
        displayName: 'translation:partial-views.clinical-pathways.labels.prosoftware-report',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static AddMedicalActionsToExamination: PartialView = new PartialView({
        name: 'add-medical-action-to-examination',
        component: PartialAddMedicalActions,
        parents: ['medical-records-details'],
        displayName: 'translation:partial-views.add-medical-actions.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static AddTreatmentProtocolsToExamination: PartialView = new PartialView({
        name: 'add-treatment-protocols-to-examination',
        component: PartialAddTreatmentProtocols,
        parents: ['medical-records-details'],
        displayName: 'translation:partial-views.add-treatment-protocols.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static AddTherapiesToExamination: PartialView = new PartialView({
        name: 'add-therapies-to-examination',
        component: PartialAddTreatmentTherapies,
        parents: ['medical-records-details'],
        displayName: 'translation:partial-views.add-treatment-therapies.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static MedicalSuggestions: PartialView = new PartialView({
        name: 'medical-suggestions',
        component: PartialMedicalSuggestions,
        displayName: 'partial-views.medical-suggestions.title',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static Contacts: PartialView = new PartialView({
        name: 'contacts',
        component: PartialContacts,
        displayName: 'partial-views.contacts.list.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static CreateContact: PartialView = new PartialView({
        name: 'contacts-create',
        component: PartialContactsCreate,
        parents: ['contacts'],
        displayName: 'partial-views.contacts.create.title',
        route: 'create',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static EditContact: PartialView = new PartialView({
        name: 'contacts-edit',
        component: PartialContactsEdit,
        parents: ['contacts', 'contact-card'],
        displayName: 'partial-views.contacts.edit.title',
        route: ':id/edit',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static ContactCard: PartialView = new PartialView({
        name: 'contact-card',
        component: PartialContactsCard,
        parents: ['contacts'],
        route: ':id/card',
        displayName: 'partial-views.contacts.card.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: true
    });
    public static Accounts: PartialView = new PartialView({
        name: 'accounts',
        component: PartialAccounts,
        displayName: 'partial-views.accounts.list.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static CreateAccount: PartialView = new PartialView({
        name: 'accounts-create',
        component: PartialAccountsCreate,
        parents: ['accounts'],
        displayName: 'partial-views.accounts.create.title',
        route: 'create',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static EditAccount: PartialView = new PartialView({
        name: 'accounts-edit',
        component: PartialAccountsEdit,
        parents: ['accounts', 'account-card'],
        displayName: 'partial-views.accounts.edit.title',
        route: ':id/edit',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static AccountCard: PartialView = new PartialView({
        name: 'account-card',
        component: PartialAccountsCard,
        parents: ['accounts'],
        route: ':id/card',
        displayName: 'partial-views.accounts.card.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: true
    });
    public static EntityContactMoments: PartialView = new PartialView({
        name: 'entity-contact-moments',
        component: PartialEntityContactMoments,
        parents: ['account-card', 'contact-card'],
        route: 'contact-moments',
        displayName: 'partial-views.entity-contact-moments.list.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static EntityContactMomentsDetails: PartialView = new PartialView({
        name: 'entity-contact-moments-details',
        component: PartialEntityContactMomentsDetails,
        parents: ['entity-contact-moments'],
        route: ':id/details',
        displayName: 'partial-views.entity-contact-moments.details.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static SendEmailToPatient: PartialView = new PartialView({
        name: 'send-email-to-patient',
        component: PartialSendEmailToPatient,
        displayName: 'partial-views.send-email-to-patient.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: true
    });
    public static SendEmailToEntity: PartialView = new PartialView({
        name: 'send-email-to-entity',
        component: PartialSendEmailToEntity,
        displayName: 'partial-views.send-email-to-entity.title',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: true
    });
    public static MedicationDetails: PartialView = new PartialView({
        name: 'medication-details-details',
        component: PartialMedicationDetails,
        displayName: 'partial-views.medications.details.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: true
    });
    public static MedicalExaminationPhases: PartialView = new PartialView({
        name: 'medical-examination-phases',
        component: PartialMedicalExaminationPhases,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-examination-phases.list.title',
        route: 'examination-phases',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalExaminationPhase: PartialView = new PartialView({
        name: 'medical-examination-phases-create',
        component: PartialMedicalExaminationPhasesCreate,
        parents: ['medical-examination-phases'],
        displayName: 'partial-views.medical-examination-phases.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalExaminationPhase: PartialView = new PartialView({
        name: 'medical-examination-phases-edit',
        component: PartialMedicalExaminationPhasesEdit,
        parents: ['medical-examination-phases'],
        displayName: 'partial-views.medical-examination-phases.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalQuestionnaires: PartialView = new PartialView({
        name: 'medical-questionnaires',
        component: PartialMedicalQuestionnaires,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-questionnaires.list.title',
        route: 'medical-questionnaires',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalQuestionnaire: PartialView = new PartialView({
        name: 'medical-questionnaires-create',
        component: PartialMedicalQuestionnairesCreate,
        parents: ['medical-questionnaires'],
        displayName: 'partial-views.medical-questionnaires.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalQuestionnaires: PartialView = new PartialView({
        name: 'medical-questionnaires-edit',
        component: PartialMedicalQuestionnairesEdit,
        parents: ['medical-questionnaires'],
        displayName: 'partial-views.medical-questionnaires.edit.title',
        route: ':id/edit',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static MedicalQuestions: PartialView = new PartialView({
        name: 'medical-questions',
        component: PartialMedicalQuestions,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-questions.list.title',
        route: 'medical-questions',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalQuestion: PartialView = new PartialView({
        name: 'medical-questions-create',
        component: PartialMedicalQuestionsCreate,
        parents: ['medical-questions'],
        displayName: 'partial-views.medical-questions.create.title',
        route: 'create',
        width: 650,
        minWidth: 650,
        maxWidth: 650,
        allowMultiple: false
    });
    public static EditMedicalQuestion: PartialView = new PartialView({
        name: 'medical-questions-edit',
        component: PartialMedicalQuestionsEdit,
        parents: ['medical-questions'],
        displayName: 'partial-views.medical-questions.edit.title',
        route: ':id/edit',
        width: 650,
        minWidth: 650,
        maxWidth: 650,
        allowMultiple: false
    });
    public static DifferentalDiagnoses: PartialView = new PartialView({
        name: 'differential-diagnoses',
        component: PartialDifferentialDiagnoses,
        parents: ['practice/templates'],
        displayName: 'partial-views.differential-diagnoses.list.title',
        route: 'differential-diagnoses',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateDifferentialDiagnosis: PartialView = new PartialView({
        name: 'differential-diagnoses-create',
        component: PartialDifferentialDiagnosesCreate,
        parents: ['differential-diagnoses'],
        displayName: 'partial-views.differential-diagnoses.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditDifferentialDiagnosis: PartialView = new PartialView({
        name: 'differential-diagnoses-edit',
        component: PartialDifferentialDiagnosesEdit,
        parents: ['differential-diagnoses'],
        displayName: 'partial-views.differential-diagnoses.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static Translations: PartialView = new PartialView({
        name: 'translations',
        component: PartialTranslations,
        displayName: 'partial-views.translations.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static HealthcareCodes: PartialView = new PartialView({
        name: 'healthcare-codes',
        component: PartialHealthcareCodes,
        displayName: 'partial-views.healthcare-codes.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static UploadFiles: PartialView = new PartialView({
        name: 'upload-files',
        component: PartialUploadFiles,
        displayName: 'partial-views.upload-files.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static InformationSheet: PartialView = new PartialView({
        name: 'information-sheet',
        component: PartialInformationSheet,
        displayName: 'partial-views.information-sheet.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static MedicalExaminationActions: PartialView = new PartialView({
        name: 'medical-examination-actions',
        component: PartialMedicalExaminationActions,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-examination-actions.list.title',
        route: 'examination-actions',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalExaminationAction: PartialView = new PartialView({
        name: 'medical-examination-actions-create',
        component: PartialMedicalExaminationActionsCreate,
        parents: ['medical-examination-actions'],
        displayName: 'partial-views.medical-examination-actions.create.title',
        route: 'create',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static EditMedicalItem: PartialView = new PartialView({
        name: 'edit-medical-item',
        component: PartialEditMedicalItem,
        displayName: 'translation:partial-views.edit-medical-item.title',
        width: 700,
        minWidth: 700,
        maxWidth: 700,
        allowMultiple: false
    });
    public static EditMedicalExaminationAction: PartialView = new PartialView({
        name: 'medical-examination-actions-edit',
        component: PartialMedicalExaminationActionsEdit,
        parents: ['medical-examination-actions'],
        displayName: 'partial-views.medical-examination-actions.edit.title',
        route: ':id/edit',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static MedicalExaminationModels: PartialView = new PartialView({
        name: 'medical-examination-models',
        component: PartialMedicalExaminationModels,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-examination-models.list.title',
        route: 'examination-models',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalExaminationModel: PartialView = new PartialView({
        name: 'medical-examination-models-create',
        component: PartialMedicalExaminationModelsCreate,
        parents: ['medical-examination-models'],
        displayName: 'partial-views.medical-examination-models.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalExaminationModel: PartialView = new PartialView({
        name: 'medical-examination-models-edit',
        component: PartialMedicalExaminationModelsEdit,
        parents: ['medical-examination-models'],
        displayName: 'partial-views.medical-examination-models.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalTherapies: PartialView = new PartialView({
        name: 'medical-therapies',
        component: PartialMedicalTherapies,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-therapies.list.title',
        route: 'therapies',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalTherapy: PartialView = new PartialView({
        name: 'medical-therapies-create',
        component: PartialMedicalTherapiesCreate,
        parents: ['medical-therapies'],
        displayName: 'partial-views.medical-therapies.create.title',
        route: 'create',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static EditMedicalTherapy: PartialView = new PartialView({
        name: 'medical-therapies-edit',
        component: PartialMedicalTherapiesEdit,
        parents: ['medical-therapies'],
        displayName: 'partial-views.medical-therapies.edit.title',
        route: ':id/edit',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static MedicalExaminations: PartialView = new PartialView({
        name: 'medical-examinations',
        component: PartialMedicalExaminations,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-examinations.list.title',
        route: 'examinations',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalExamination: PartialView = new PartialView({
        name: 'medical-examinations-create',
        component: PartialMedicalExaminationsCreate,
        parents: ['medical-examinations'],
        displayName: 'partial-views.medical-examinations.create.title',
        route: 'create',
        width: 1400,
        minWidth: 1400,
        maxWidth: 1400,
        allowMultiple: false
    });
    public static EditMedicalExamination: PartialView = new PartialView({
        name: 'medical-examinations-edit',
        component: PartialMedicalExaminationsEdit,
        parents: ['medical-examinations'],
        displayName: 'partial-views.medical-examinations.edit.title',
        route: ':id/edit',
        width: 1500,
        minWidth: 1500,
        maxWidth: 1500,
        allowMultiple: false
    });
    public static MedicalStepSettings: PartialView = new PartialView({
        name: 'medical-step-settings',
        component: PartialMedicalStepSettings,
        displayName: 'partial-views.medical-step-settings.title',
        width: 850,
        minWidth: 850,
        maxWidth: 850,
        allowMultiple: false
    });
    public static DifferentalDiagnosisCategories: PartialView = new PartialView({
        name: 'differential-diagnosis-categories',
        component: PartialDifferentialDiagnosisCategories,
        parents: ['practice/templates'],
        displayName: 'partial-views.differential-diagnosis-categories.list.title',
        route: 'differential-diagnosis-categories',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateDifferentialDiagnosisCategory: PartialView = new PartialView({
        name: 'differential-diagnosis-categories-create',
        component: PartialDifferentialDiagnosisCategoriesCreate,
        parents: ['differential-diagnosis-categories'],
        displayName: 'partial-views.differential-diagnosis-categories.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditDifferentialDiagnosisCategory: PartialView = new PartialView({
        name: 'differential-diagnosis-categories-edit',
        component: PartialDifferentialDiagnosisCategoriesEdit,
        parents: ['differential-diagnosis-categories'],
        displayName: 'partial-views.differential-diagnosis-categories.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalExaminationActionCategories: PartialView = new PartialView({
        name: 'medical-examination-action-categories',
        component: PartialMedicalExaminationActionCategories,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-examination-action-categories.list.title',
        route: 'medical-examination-action-categories',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalExaminationActionCategory: PartialView = new PartialView({
        name: 'medical-examination-action-categories-create',
        component: PartialMedicalExaminationActionCategoriesCreate,
        parents: ['medical-examination-action-categories'],
        displayName: 'partial-views.medical-examination-action-categories.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalExaminationActionCategory: PartialView = new PartialView({
        name: 'medical-examination-action-categories-edit',
        component: PartialMedicalExaminationActionCategoriesEdit,
        parents: ['medical-examination-action-categories'],
        displayName: 'partial-views.medical-examination-action-categories.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalQuestionCategories: PartialView = new PartialView({
        name: 'medical-question-categories',
        component: PartialMedicalQuestionCategories,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-question-categories.list.title',
        route: 'medical-question-categories',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalQuestionCategory: PartialView = new PartialView({
        name: 'medical-question-categories-create',
        component: PartialMedicalQuestionCategoriesCreate,
        parents: ['medical-question-categories'],
        displayName: 'partial-views.medical-question-categories.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalQuestionCategory: PartialView = new PartialView({
        name: 'differential-medical-question-edit',
        component: PartialMedicalQuestionCategoriesEdit,
        parents: ['differential-medical-question'],
        displayName: 'partial-views.medical-question-categories.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalTreatmentProtocols: PartialView = new PartialView({
        name: 'medical-treatment-protocols',
        component: PartialMedicalTreatmentProtocols,
        parents: ['practice/templates'],
        displayName: 'partial-views.medical-treatment-protocols.list.title',
        route: 'treatment-protocols',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateMedicalTreatmentProtocol: PartialView = new PartialView({
        name: 'medical-treatments-protocols-create',
        component: PartialMedicalTreatmentProtocolsCreate,
        parents: ['medical-treatment-protocols'],
        displayName: 'partial-views.medical-treatment-protocols.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditMedicalTreatmentProtocol: PartialView = new PartialView({
        name: 'medical-treatments-protocols-edit',
        component: PartialMedicalTreatmentProtocolsEdit,
        parents: ['medical-treatment-protocols'],
        displayName: 'partial-views.medical-treatment-protocols.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MedicalTreatmentProtocolDefaultValues: PartialView = new PartialView({
        name: 'medical-treatments-protocols-default-values',
        component: PartialMedicalTreatmentProtocolsDefaultValues,
        parents: ['medical-treatment-protocols'],
        displayName: 'translation:partial-views.medical-treatment-protocols.default-values.title',
        route: 'default-values',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static AnatomicalRegions: PartialView = new PartialView({
        name: 'anatomical-regions',
        component: PartialAnatomicalRegions,
        parents: ['practice/templates'],
        displayName: 'partial-views.anatomical-regions.list.title',
        route: 'anatomical-regions',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateAnatomicalRegion: PartialView = new PartialView({
        name: 'anatomical-regions-create',
        component: PartialAnatomicalRegionsCreate,
        parents: ['anatomical-regions'],
        displayName: 'partial-views.anatomical-regions.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditAnatomicalRegion: PartialView = new PartialView({
        name: 'anatomical-regions-edit',
        component: PartialAnatomicalRegionsEdit,
        parents: ['anatomical-regions'],
        displayName: 'partial-views.anatomical-regions.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static PracticeTemplates: PartialView = new PartialView({
        name: 'practice-templates',
        component: PartialPracticeTemplates,
        displayName: 'partial-views.templates.title',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static MasterData: PartialView = new PartialView({
        name: 'master-data',
        component: PartialMasterData,
        displayName: 'partial-views.master-data.list.title',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static ManageMasterData: PartialView = new PartialView({
        name: 'manage-master-data',
        component: PartialMasterDataManage,
        parents: ['master-data'],
        route: ':id/manage',
        displayName: 'partial-views.master-data.manage.title',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static ManageMasterDataFields: PartialView = new PartialView({
        name: 'manage-master-data-fields',
        component: PartialMasterDataFields,
        parents: ['manage-master'],
        route: ':id/fields',
        displayName: 'partial-views.master-data.fields.title',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static Scheduler: PartialView = new PartialView({
        name: 'scheduler',
        component: PartialScheduler,
        displayName: 'partial-views.scheduler.title',
        width: 1285,
        minWidth: 465,
        maxWidth: 1285,
        allowMultiple: false,
        canAdjustWidth: true
    });
    public static PracticeSettings: PartialView = new PartialView({
        name: 'practice-settings',
        component: PartialPracticeSettings,
        displayName: 'translation:partial-views.practice-settings.labels.title',
        width: 300,
        minWidth: 300,
        maxWidth: 300,
        allowMultiple: false
    });
    public static ManagePractice: PartialView = new PartialView({
        name: 'manage-practice',
        component: PartialManagePractice,
        parents: ['practice-settings'],
        route: 'configure',
        displayName: 'translation:partial-views.manage-practice.labels.title',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static ManageUsers: PartialView = new PartialView({
        name: 'manage-users',
        component: PartialManageUsers,
        parents: ['practice-settings'],
        route: 'users',
        displayName: 'translation:partial-views.manage-users.labels.title',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static ManageInvitationsCreate: PartialView = new PartialView({
        name: 'manage-users-invitation',
        component: PartialManageInvitationsCreate,
        parents: ['manage-users'],
        route: 'invitations',
        displayName: 'translation:partial-views.manage-users.labels.invite',
        width: 700,
        minWidth: 700,
        maxWidth: 700,
        allowMultiple: false
    });
    public static ManageUsersEdit: PartialView = new PartialView({
        name: 'manage-users-edit',
        component: PartialManageUsersEdit,
        parents: ['manage-users'],
        route: ':id/edit',
        displayName: 'translation:partial-views.manage-users.edit.title',
        width: 700,
        minWidth: 700,
        maxWidth: 700,
        allowMultiple: false
    });
    public static ManageInvitationsEdit: PartialView = new PartialView({
        name: 'manage-invitations-edit',
        component: PartialManageInvitationsEdit,
        parents: ['manage-users'],
        route: 'invitations/:id/edit',
        displayName: 'translation:partial-views.manage-users.manage-invitations.title',
        width: 700,
        minWidth: 700,
        maxWidth: 700,
        allowMultiple: false
    });
    public static ManageTeams: PartialView = new PartialView({
        name: 'manage-teams',
        component: PartialManageTeams,
        parents: ['practice-settings'],
        route: 'teams',
        displayName: 'translation:partial-views.manage-teams.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static ManageScheduler: PartialView = new PartialView({
        name: 'manage-scheduler',
        component: PartialManageScheduler,
        parents: ['practice-settings'],
        route: 'scheduler',
        displayName: 'translation:partial-views.manage-scheduler.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateTeam: PartialView = new PartialView({
        name: 'manage-teams-create',
        component: PartialManageTeamsCreate,
        parents: ['manage-teams'],
        displayName: 'partial-views.manage-teams.create.title',
        route: 'create',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static EditTeam: PartialView = new PartialView({
        name: 'manage-teams-edit',
        component: PartialManageTeamsEdit,
        parents: ['manage-teams'],
        displayName: 'partial-views.manage-teams.edit.title',
        route: ':id/edit',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static ManageSchedules: PartialView = new PartialView({
        name: 'manage-schedules',
        component: PartialManageSchedules,
        parents: ['practice-settings'],
        route: 'schedules',
        displayName: 'translation:partial-views.manage-schedules.labels.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static CreateSchedule: PartialView = new PartialView({
        name: 'manage-schedules-create',
        component: PartialManageSchedulesCreate,
        parents: ['manage-schedules'],
        displayName: 'partial-views.manage-schedules.create.title',
        route: 'create',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        canAdjustWidth: true,
        allowMultiple: false
    });
    public static EditSchedule: PartialView = new PartialView({
        name: 'manage-schedules-edit',
        component: PartialManageSchedulesEdit,
        parents: ['manage-schedules'],
        displayName: 'partial-views.manage-schedules.edit.title',
        route: ':id/edit',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        canAdjustWidth: true,
        allowMultiple: false
    });
    public static ScheduleTimeslot: PartialView = new PartialView({
        name: 'partial-manage-schedules-timeslot',
        component: PartialManageSchedulesTimeslot,
        displayName: 'partial-views.manage-schedules.timeslot.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static ManageAppointmentTypes: PartialView = new PartialView({
        name: 'manage-appointment-types',
        component: PartialManageAppointmentTypes,
        parents: ['practice-settings'],
        route: 'appointment-types',
        displayName: 'translation:partial-views.manage-appointment-types.labels.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static CreateAppointmentType: PartialView = new PartialView({
        name: 'manage-appointment-types-create',
        component: PartialManageAppointmentTypesCreate,
        parents: ['manage-appointment-types'],
        displayName: 'partial-views.manage-appointment-types.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditAppointmentType: PartialView = new PartialView({
        name: 'manage-appointment-types-edit',
        component: PartialManageAppointmentTypesEdit,
        parents: ['manage-appointment-types'],
        displayName: 'partial-views.manage-appointment-types.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static HealthcarePrices: PartialView = new PartialView({
        name: 'manage-healthcare-prices',
        component: PartialManageHealthcarePrices,
        displayName: 'partial-views.manage-healthcare-prices.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static ManageVatCategories: PartialView = new PartialView({
        name: 'manage-vat-categories',
        component: PartialManageVatCategories,
        parents: ['practice-settings'],
        route: 'vat-categories',
        displayName: 'translation:partial-views.manage-vat-categories.labels.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static CreateVatCategory: PartialView = new PartialView({
        name: 'manage-vat-categories-create',
        component: PartialManageVatCategoriesCreate,
        parents: ['manage-vat-categories'],
        displayName: 'partial-views.manage-vat-categories.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditVatCategory: PartialView = new PartialView({
        name: 'manage-vat-categories-edit',
        component: PartialManageVatCategoriesEdit,
        parents: ['manage-vat-categories'],
        displayName: 'partial-views.manage-vat-categories.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static ManagePracticeLocations: PartialView = new PartialView({
        name: 'manage-practice-locations',
        component: PartialManagePracticeLocations,
        parents: ['practice-settings'],
        route: 'practice-locations',
        displayName: 'translation:partial-views.manage-practice-locations.labels.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static CreatePracticeLocation: PartialView = new PartialView({
        name: 'manage-practice-locations-create',
        component: PartialManagePracticeLocationsCreate,
        parents: ['manage-practice-locations'],
        displayName: 'partial-views.manage-practice-locations.create.title',
        route: 'create',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static EditPracticeLocation: PartialView = new PartialView({
        name: 'manage-practice-locations-edit',
        component: PartialManagePracticeLocationsEdit,
        parents: ['manage-practice-locations'],
        displayName: 'partial-views.manage-practice-locations.edit.title',
        route: ':id/edit',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static PracticeLocationsRoom: PartialView = new PartialView({
        name: 'manage-practice-locations-room',
        component: PartialManagePracticeLocationsRoom,
        displayName: 'partial-views.manage-practice-locations.room.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static ManageInsurers: PartialView = new PartialView({
        name: 'manage-insurers',
        component: PartialManageInsurers,
        parents: ['practice-settings'],
        route: 'insurers',
        displayName: 'translation:partial-views.manage-insurers.labels.title',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static CreateInsurer: PartialView = new PartialView({
        name: 'manage-insurers-create',
        component: PartialManageInsurersCreate,
        parents: ['manage-insurers'],
        displayName: 'partial-views.manage-insurers.create.title',
        route: 'create',
        width: 650,
        minWidth: 650,
        maxWidth: 650,
        allowMultiple: false
    });
    public static EditInsurer: PartialView = new PartialView({
        name: 'manage-insurers-edit',
        component: PartialManageInsurersEdit,
        parents: ['manage-insurers'],
        displayName: 'partial-views.manage-insurers.edit.title',
        route: ':id/edit',
        width: 650,
        minWidth: 650,
        maxWidth: 650,
        allowMultiple: false
    });
    public static ManageEmailTemplates: PartialView = new PartialView({
        name: 'email-templates',
        component: PartialManageEmailTemplates,
        parents: ['practice-settings'],
        displayName: 'partial-views.manage-email-templates.list.title',
        route: 'emails',
        width: 350,
        minWidth: 350,
        maxWidth: 350,
        allowMultiple: false
    });
    public static ManagePracticePrices: PartialView = new PartialView({
        name: 'manage-practice-prices',
        component: PartialManagePracticePrices,
        parents: ['practice-settings'],
        route: 'prices',
        displayName: 'translation:partial-views.manage-practice.labels.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static CreateEmailTemplate: PartialView = new PartialView({
        name: 'email-templates-create',
        component: PartialManageEmailTemplatesCreate,
        parents: ['email-templates'],
        displayName: 'partial-views.manage-email-templates.create.title',
        route: 'create',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static EditEmailTemplate: PartialView = new PartialView({
        name: 'email-templates-edit',
        component: PartialManageEmailTemplatesEdit,
        parents: ['email-templates'],
        displayName: 'partial-views.manage-email-templates.edit.title',
        route: ':id/edit',
        width: 800,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static CreateAppointment: PartialView = new PartialView({
        name: 'create-appointment',
        component: PartialAppointmentsCreate,
        displayName: 'translation:partial-views.appointments.create.title',
        route: 'appointments/create',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static EditAppointment: PartialView = new PartialView({
        name: 'edit-appointment',
        component: PartialAppointmentsEdit,
        displayName: 'translation:partial-views.appointments.edit.title',
        route: 'appointments/:id/edit',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static Tasks: PartialView = new PartialView({
        name: 'tasks',
        component: PartialTasks,
        displayName: 'partial-views.tasks.title',
        width: 1285,
        minWidth: 1285,
        maxWidth: 1285,
        allowMultiple: false,
        canAdjustWidth: false
    });
    public static CreateTaskList: PartialView = new PartialView({
        name: 'create-task-list',
        component: PartialTaskListsCreate,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.lists.create.title',
        route: 'lists/create',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static EditTaskList: PartialView = new PartialView({
        name: 'edit-task-list',
        component: PartialTaskListsEdit,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.lists.edit.title',
        route: 'lists/:id/edit',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateTask: PartialView = new PartialView({
        name: 'create-task',
        component: PartialTasksCreate,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.create.title',
        route: 'create',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static EditTask: PartialView = new PartialView({
        name: 'edit-task',
        component: PartialTasksEdit,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.edit.title',
        route: ':id/edit',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static HealthcareTaskPriorityLabels: PartialView = new PartialView({
        name: 'healthcare-task-labels-priority',
        component: PartialTaskLabels,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.priorities.list.title',
        route: 'priorities',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static HealthcareTaskStatusesLabels: PartialView = new PartialView({
        name: 'healthcare-task-labels-status',
        component: PartialTaskLabels,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.statuses.list.title',
        route: 'statuses',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static HealthcareTaskLabels: PartialView = new PartialView({
        name: 'healthcare-task-labels',
        component: PartialTaskLabels,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.labels.list.title',
        route: 'labels',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static CreateHealthcareTaskLabel: PartialView = new PartialView({
        name: 'create-task-label',
        component: PartialTaskLabelsCreate,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.labels.create.title',
        route: 'create',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static EditHealthcareTaskLabel: PartialView = new PartialView({
        name: 'edit-task-label',
        component: PartialTaskLabelsEdit,
        parents: ['tasks'],
        displayName: 'translation:partial-views.tasks.labels.edit.title',
        route: ':id/edit',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static UserPosts: PartialView = new PartialView({
        name: 'user-posts',
        component: PartialUserPosts,
        parents: ['tasks'],
        displayName: 'translation:partial-views.user-posts.title',
        route: 'posts',
        width: 650,
        minWidth: 650,
        maxWidth: 650,
        allowMultiple: false
    });
    public static HealthcareInvoices: PartialView = new PartialView({
        name: 'healthcare-invoices',
        component: PartialHealthcareInvoices,
        parents: [],
        displayName: 'translation:partial-views.healthcare-invoices.title',
        route: 'invoices',
        width: 700,
        minWidth: 800,
        maxWidth: 800,
        allowMultiple: false
    });
    public static EditHealthcareInvoice: PartialView = new PartialView({
        name: 'edit-healthcare-invoice',
        component: PartialHealthcareInvoicesEdit,
        parents: ['healthcare-invoices'],
        displayName: 'translation:partial-views.healthcare-invoices.edit.title',
        route: ':id/edit',
        width: 1200,
        minWidth: 1200,
        maxWidth: 1200,
        allowMultiple: false
    });
    public static HealthcareInvoicesLineSelection: PartialView = new PartialView({
        name: 'healthcare-invoices-line-selection',
        component: PartialHealthcareInvoicesLineSelection,
        displayName: 'translation:partial-views.healthcare-invoices.line-selection.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static HealthcareInvoicesPreview: PartialView = new PartialView({
        name: 'healthcare-invoices-preview',
        component: PartialHealthcareInvoicesPreview,
        displayName: 'translation:partial-views.healthcare-invoices.preview.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static PracticeIntegrations: PartialView = new PartialView({
        name: 'practice-integrations',
        component: PartialPracticeIntegrations,
        displayName: 'translation:partial-views.practice-integrations.labels.title',
        width: 300,
        minWidth: 300,
        maxWidth: 300,
        allowMultiple: false
    });
    public static IntegrationsVecozo: PartialView = new PartialView({
        name: 'integrations-vecozo',
        component: PartialIntegrationsVecozo,
        parents: ['practice-integrations'],
        route: 'vecozo',
        displayName: 'Vecozo',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static IntegrationsZorgdomein: PartialView = new PartialView({
        name: 'integrations-zorgdomein',
        component: PartialIntegrationsZorgdomein,
        parents: ['practice-integrations'],
        route: 'zorgdomein',
        displayName: 'Zorgdomein',
        width: 900,
        minWidth: 900,
        maxWidth: 900,
        allowMultiple: false
    });
    public static IntegrationsZorgmail: PartialView = new PartialView({
        name: 'integrations-zorgmail',
        component: PartialIntegrationsZorgmail,
        parents: ['practice-integrations'],
        route: 'zorgmail',
        displayName: 'Zorgmail',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        allowMultiple: false
    });
    public static PatientCovCheck: PartialView = new PartialView({
        name: 'patient-cov-check',
        component: PartialPatientCovCheck,
        displayName: 'partial-views.patient-cov-check.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static PatientInsurances: PartialView = new PartialView({
        name: 'patient-insurances',
        component: PartialPatientInsurances,
        parents: ['patient-card'],
        route: 'insurances',
        displayName: 'partial-views.patient-insurances.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
    public static PatientInvoices: PartialView = new PartialView({
        name: 'patient-invoices',
        component: PartialPatientInvoices,
        parents: ['patient-card'],
        route: 'invoices',
        displayName: 'translation:partial-views.patient-invoices.title',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static PatientAppointments: PartialView = new PartialView({
        name: 'patient-invoices',
        component: PartialPatientAppointments,
        parents: ['patient-card'],
        route: 'appointments',
        displayName: 'translation:partial-views.patient-appointments.title',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static DeclarationPerformances: PartialView = new PartialView({
        name: 'declaration-performances',
        component: PartialDeclarationPerformances,
        displayName: 'translation:partial-views.declaration-performances.labels.title',
        width: 1100,
        minWidth: 1100,
        maxWidth: 1100,
        allowMultiple: false
    });
    public static CreateDeclarationPerformances: PartialView = new PartialView({
        name: 'declaration-performances-create',
        component: PartialDeclarationPerformancesCreate,
        parents: ['declaration-performances'],
        route: 'create',
        displayName: 'translation:partial-views.declaration-performances.create.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static EditDeclarationPerformances: PartialView = new PartialView({
        name: 'declaration-performances-edit',
        component: PartialDeclarationPerformancesEdit,
        parents: ['declaration-performances'],
        route: ':id/edit',
        displayName: 'translation:partial-views.declaration-performances.edit.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static Declarations: PartialView = new PartialView({
        name: 'declarations',
        component: PartialDeclarations,
        displayName: 'translation:partial-views.declarations.labels.title',
        width: 1150,
        minWidth: 1150,
        maxWidth: 1150,
        allowMultiple: false
    });
    public static EditDeclaration: PartialView = new PartialView({
        name: 'declarations-edit',
        component: PartialDeclarationsEdit,
        parents: ['declarations'],
        route: ':id/edit',
        displayName: 'translation:partial-views.declarations.edit.title',
        width: 600,
        minWidth: 600,
        maxWidth: 600,
        allowMultiple: false
    });
    public static ProcessDeclaration: PartialView = new PartialView({
        name: 'declarations-process',
        component: PartialDeclarationsProcess,
        displayName: 'translation:partial-views.declarations.process.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static DeclarationsLogs: PartialView = new PartialView({
        name: 'declarations-logs',
        component: PartialDeclarationsLogs,
        parents: ['declarations'],
        displayName: 'translation:partial-views.declarations.logs.title',
        route: 'logs',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        allowMultiple: false
    });
    public static Filters: PartialView = new PartialView({
        name: 'filters',
        component: PartialFilters,
        displayName: 'partial-views.filters.title',
        width: 425,
        minWidth: 425,
        maxWidth: 425,
        allowMultiple: false
    });
    public static DocumentsPreview: PartialView = new PartialView({
        name: 'documents-preview',
        component: PartialDocumentsPreview,
        displayName: 'translation:partial-views.documents.details.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static SignDocument: PartialView = new PartialView({
        name: 'sign-document',
        component: PartialSignDocument,
        displayName: 'translation:partial-views.sign-document.labels.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static UserSettings: PartialView = new PartialView({
        name: 'user-settings',
        component: PartialUserSettings,
        displayName: 'translation:partial-views.user-settings.labels.title',
        width: 1000,
        minWidth: 1000,
        maxWidth: 1000,
        allowMultiple: false
    });
    public static ConnectTherapies: PartialView = new PartialView({
        name: 'connect-therapies',
        component: PartialConnectTherapies,
        parents: ['medical-records-details'],
        displayName: 'translation:partial-views.connect-therapies.labels.title',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        allowMultiple: false
    });
}
