import { I18N } from '@aurelia/i18n';
import { GetUserResponse, UserRoles } from '@wecore/sdk-core';
import {
    AppointmentsApiClient,
    ExaminationRoom,
    GetAppointmentResponse,
    GetScheduleResponse,
    PracticeLocation,
    PracticeLocationsApiClient,
    ScheduleTimeSlot,
    SchedulesApiClient,
    UserEntityReference
} from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';

import { SchedulerSettings } from '@wecore/sdk-management';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import {
    addSeconds,
    areIntervalsOverlapping,
    differenceInSeconds,
    endOfDay,
    format,
    formatISO,
    getHours,
    getMinutes,
    intervalToDuration,
    secondsToMinutes,
    setHours,
    setMinutes,
    startOfDay
} from 'date-fns';
import { FormatTimeValueConverter } from '../../converters/format-time';
import { Broadcasts } from '../../infra/broadcasts/broadcasts';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { cloneDeep, dayAndTimeToDate, getSchedulerColumnHeight, getTotalAmountOfSlots, toDayOfWeek } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';
import { SchedulerState } from '../../models/scheduler-state';
import { ModalService } from '../../services/service.modals';
import { NotificationService } from '../../services/service.notifications';

@containerless
@inject(IEventAggregator, AppointmentsApiClient, ModalService, I18N, SchedulesApiClient, PracticeLocationsApiClient, NotificationService, ErrorHandler)
export class BxSchedulerColumn {
    @bindable() public date: Date;
    @bindable() public settings: SchedulerSettings;
    @bindable() public periods: SchedulerPeriod[];
    @bindable() public columns: BxSchedulerColumn[];
    @bindable() public index: number;
    @bindable() public user: GetUserResponse;
    @bindable() public wrapper: SchedulerRoomWrapper;
    @bindable() public hasUserFilter: boolean = false;
    @bindable() public state: SchedulerState;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onAppointmentClick: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentCreate: (start: Date, end: Date, userId: string, roomId: string, locationId: string) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentEdit: (appointment: GetAppointmentResponse) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentDelete: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onAppointmentMove: (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onAppointmentResize: (appointment: GetAppointmentResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; appointment: GetAppointmentResponse }>;
    @bindable() public onPatientCard: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onConfirmAppointment: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkNoShow: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkNoShow: (appointment: GetAppointmentResponse) => Promise<{ success: boolean }>;

    public appointments: GetAppointmentResponse[] = [];
    public layers: { [key: string]: { zIndex: number } } = {};
    public column: HTMLDivElement;
    public renderContainer: HTMLDivElement;
    public height: number;
    public baseLoaded: boolean = false;
    public loader: { amount: number; top: number[]; height: number[] };
    public UserRoles: typeof UserRoles = UserRoles;

    public schedules: {
        schedule: GetScheduleResponse;
        slotsAndRooms: {
            slot: ScheduleTimeSlot;
            room: ExaminationRoom;
        }[];
    }[];

    private gridSnap: number;

    private originalHeight: number;
    private originalY: number;
    private originalTop: number;
    private differenceBetweenMouseAndTopOfClone: number;
    private activeContainer: HTMLDivElement;
    private activeIndex: number;
    private dragCb: (e: MouseEvent) => void;
    private dragEndCb: (e: MouseEvent) => void;
    private dragKeyCb: (e: KeyboardEvent) => void;
    private hoverCb: (e: MouseEvent) => void;
    private contextMenuCb: (e: MouseEvent) => void;
    private subscriptions: IDisposable[] = [];

    public constructor(
        private readonly events: IEventAggregator, //
        private readonly appointmentsApi: AppointmentsApiClient,
        private readonly modalService: ModalService,
        private readonly t: I18N,
        private readonly schedulesApi: SchedulesApiClient,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly notifications: NotificationService,
        private readonly errorHandler: ErrorHandler
    ) {}

    public bound(): void {
        this.height = getSchedulerColumnHeight(this.settings, this.periods, this.settings.periodHeight);
        this.column.style.height = `${this.height}px`;

        const totalSlots = getTotalAmountOfSlots(this.date, this.settings);
        this.gridSnap = 100 / totalSlots;

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                CustomEvents.SchedulerAppointmentDeleted,
                (data: {
                    columnDate: Date; //
                    appointment: GetAppointmentResponse;
                }) => {
                    // If this is not the column that the appointment belongs to, don't do anything.
                    // Otherwise the appointment will be rendered on all columns.
                    if (format(data.columnDate, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd')) return;

                    // If the appointment is deleted, remove it from the list.
                    const index = this.appointments.findIndex((x) => x.id === data.appointment.id);
                    if (index === -1) return;

                    this.appointments.splice(index, 1);
                    this.render(this.appointments);
                    this.events.publish(CustomEvents.SchedulerAppointmentRefresh, data);
                }
            ),
            this.events.subscribe(Broadcasts.AppointmentCreated, (data: { appointment: GetAppointmentResponse }) => {
                // Only add the appointment to the column if the appointment is created on the same day as the column.
                if (format(this.date, 'yyyyMMdd') !== format(data.appointment.start, 'yyyyMMdd')) return;

                const addAppointment = () => {
                    if (this.appointments.findIndex((x) => x.id === data.appointment.id) === -1) {
                        const appointments = [...this.appointments, data.appointment];
                        this.render(appointments);
                        this.events.publish(CustomEvents.SchedulerAppointmentRefresh, data);
                    }
                };

                if (isDefined(this.wrapper)) {
                    // Room mode.
                    if (this.wrapper.room.id === data.appointment.examinationRoom.id) {
                        addAppointment();
                    }
                } else {
                    // User mode.
                    if (this.hasUserFilter) {
                        // With mulitple users filtered.
                        if (this.user.id === data.appointment.practitioner.id) {
                            if (format(this.date, 'yyyyMMdd') === format(data.appointment.start, 'yyyyMMdd')) {
                                addAppointment();
                            }
                        }
                    } else {
                        // With the authenticated user.
                        if (format(this.date, 'yyyyMMdd') === format(data.appointment.start, 'yyyyMMdd')) {
                            addAppointment();
                        }
                    }
                }
            }),
            this.events.subscribe(
                Broadcasts.AppointmentUpdated,
                (data: {
                    appointment: GetAppointmentResponse; //
                    oldStart: Date;
                    oldEnd: Date;
                    oldRoom: ExaminationRoom;
                    oldPractitioner: UserEntityReference;
                }) => {
                    // Because the DRAG-END and RESIZE-END events also change the DOM elements
                    // of the appointments we need to wait a bit before we manipulate the DOM
                    // in the autosync event. Otherwise the DOM elements might be in a race condition.
                    // and show the appointments in a wrong state.
                    // For example if you move an appointment to another column, the appointment
                    // will be removed from the DOM and added to the new column. But if the autosync
                    // event is fired before the DOM elements are updated, the appointment will be
                    // added to the old column again.
                    setTimeout(() => {
                        const reAddAppointment = () => {
                            // If this is not the column that the appointment belongs to, don't do anything.
                            // Otherwise the appointment will be rendered on all columns.
                            if (
                                format(data.appointment.start, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd') && //
                                format(data.appointment.end, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd')
                            )
                                return;

                            if (this.appointments.findIndex((x) => x.id === data.appointment.id) === -1) {
                                const appointments = [...this.appointments, data.appointment];
                                this.render(appointments);
                                this.events.publish(CustomEvents.SchedulerAppointmentRefresh, data);
                            }
                        };

                        // Always remove the old appointment from any column.
                        const index = this.appointments.findIndex((x) => x.id === data.appointment.id);
                        if (index > -1) {
                            // Make sure the DOM element is removed also
                            document.getElementById(`appointment-${data.appointment.id}`)?.remove();

                            const appointments = [...this.appointments];
                            appointments.splice(index, 1);
                            this.render(appointments);
                        }

                        // Lets re-add the appointment to the column if it matches the filter.
                        if (isDefined(this.wrapper)) {
                            // Room mode.
                            if (data.appointment.examinationRoom.id !== data.oldRoom.id) {
                                // The appointment has moved to a different room.
                                if (this.wrapper.room.id === data.appointment.examinationRoom.id) {
                                    // The appointment is re-added to the new room.
                                    reAddAppointment();
                                }
                            } else {
                                // The appointment has not moved to a different room.
                                if (this.wrapper.room.id === data.oldRoom.id) {
                                    // The appointment is re-added to the same room.
                                    reAddAppointment();
                                }
                            }
                        } else {
                            // User mode.
                            if (this.hasUserFilter) {
                                // With multiple users filtered.
                                if (data.appointment.practitioner.id != data.oldPractitioner.id) {
                                    // The appointment has moved to a different practitioner.
                                    if (this.user.id === data.appointment.practitioner.id) {
                                        reAddAppointment();
                                    }
                                } else {
                                    // The appointment is not moved to a different practitioner.
                                    // Check if the appointment is moved to a different day.
                                    if (format(data.appointment.start, 'yyyyMMdd') !== format(data.oldStart, 'yyyyMMdd')) {
                                        // The appointment is moved to a different day.
                                        if (format(this.date, 'yyyyMMdd') === format(data.appointment.start, 'yyyyMMdd')) {
                                            // Add the appointment to the column if the date matches the appointment date.
                                            reAddAppointment();
                                        }
                                    } else {
                                        // The appointment has not moved to a different day.
                                        if (this.user.id === data.appointment.practitioner.id) {
                                            // The column user matches the appointment user.
                                            reAddAppointment();
                                        }
                                    }
                                }
                            } else {
                                // With the authenticated user.
                                if (format(this.date, 'yyyyMMdd') === format(data.appointment.start, 'yyyyMMdd')) {
                                    reAddAppointment();
                                }
                            }
                        }
                    }, 150);
                }
            ),
            this.events.subscribe(Broadcasts.AppointmentDeleted, (data: { appointment: GetAppointmentResponse }) => {
                // If the appointment is deleted, remove it from the list.
                const index = this.appointments.findIndex((x) => x.id === data.appointment.id);
                if (index === -1) return;

                this.appointments.splice(index, 1);
                this.render(this.appointments);
                this.events.publish(CustomEvents.SchedulerAppointmentRefresh, data);
            })
        ];
    }

    public async attached(): Promise<void> {
        this.init();
        this.setBaseLoaders();

        this.hoverCb = (e) => this.handleHover(e);
        this.column.addEventListener('mousemove', this.hoverCb);
    }

    public async refresh(): Promise<void> {
        await this.init();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
        document.removeEventListener('mousemove', this.hoverCb);
        document.removeEventListener('contextmenu', this.contextMenuCb);
    }

    public handleHover(e: MouseEvent): void {
        // Remove any other element hover element.
        document.getElementById('hover-appointment')?.remove();

        // Don't render the hover appointment when dragging.
        if (
            this.state.isColumnDragging || //
            this.state.isAppointmentDragging ||
            this.state.isAppointmentResizing ||
            this.state.isSaving ||
            this.state.isEditing ||
            !this.baseLoaded
        )
            return;

        let container = document.elementFromPoint(e.clientX, e.clientY);
        if (isNotDefined(container)) return;

        // Lets check if we're hovering over a column.
        if (!container.id.includes('render-area')) {
            // If not, it could be that we're hovering above a schedule.
            // so lets find the closest schedule.
            const schedule = container.closest('[data-type="schedule"]');
            // If no scehdule is found, we're not hovering above a column.
            if (isNotDefined(schedule)) return;

            // Always make sure the column is the render area.
            container = this.column.querySelector('[data-type="render-area"]');
        }
        // Get the position of the column.
        const pos = container.getBoundingClientRect();
        // Get mouse position relative to the column.
        let y = e.clientY - pos.top - 5;
        if (y < 0) y = 0;
        if (y > this.height) y = this.height;
        const { element, start, end } = this.createAppointmentElement('hover-appointment', y);

        element.classList.add('opacity-50');
        element.classList.add('bg-gray-200');
        element.style.zIndex = '10000';

        this.contextMenuCb = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            // Make sure not to interfere with the context menu of the appointment.
            const appointment = target.closest('[data-type="appointment"]');
            if (isDefined(appointment)) return;

            // Only show the context menu when the user right clicks on the render area.
            const renderArea = target.closest('[data-type="render-area"]');
            if (isNotDefined(renderArea)) return;

            if (isNotDefined(this.column)) return;

            e.preventDefault();

            const clones = document.querySelectorAll('[data-type="context-menu-clone"]');
            clones.forEach((c) => c.remove());

            const doc = document.querySelector('[data-type="app"]');
            const menu = this.column.querySelector('[data-type="column-context-menu"]') as HTMLDivElement;

            const clone = menu.cloneNode(true) as HTMLDivElement;
            clone.classList.remove('hidden');
            clone.setAttribute('data-type', 'context-menu-clone');

            clone.style.left = `${e.clientX}px`;
            clone.style.top = `${e.clientY}px`;
            clone.style.zIndex = '1000';

            const createBtn = clone.querySelector('[data-function="create"]');
            createBtn.addEventListener('mousedown', async () => {
                const userId = isNotDefined(this.wrapper) ? this.user.id : null;
                const roomId = isDefined(this.wrapper) ? this.wrapper.room.id : null;
                const locationId = isDefined(this.wrapper) ? this.wrapper.location.id : null;
                const { success } = await this.onAppointmentCreate(start, end, userId, roomId, locationId);
                if (success) this.init();
            });

            const pastBtn = clone.querySelector('[data-function="paste"]');

            if (isDefined(this.state.cut)) {
                pastBtn.addEventListener('mousedown', () => this.handlePaste(start));
            } else pastBtn.remove();

            // const cutBtn = clone.querySelector('[data-function="cut"]');
            // cutBtn.addEventListener('mousedown', () => this.cut());

            this.setSlotTime(clone, start);
            doc.appendChild(clone);
        };

        document.addEventListener('contextmenu', (e) => this.contextMenuCb(e));

        // Set the slot time.
        this.setSlotTime(element, start);
        container.appendChild(element);
    }

    public hideHover(_: MouseEvent): void {
        // Remove hover element
        document.getElementById('hover-appointment')?.remove();
    }

    public handleDragStart(e: MouseEvent): void {
        if (
            // Only left click.
            e.button !== 0 || //
            // No new ones while saving
            this.state.isSaving ||
            this.state.isEditing ||
            !this.baseLoaded
        )
            return;

        // If the user is dragging an appointment, don't do anything.
        let el = document.elementFromPoint(e.clientX, e.clientY);

        const appointment = el.closest('[data-type="appointment"]');
        if (isDefined(appointment)) return;

        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.state.isColumnDragging = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);

                // Get the position of the column.
                const pos = this.column.getBoundingClientRect();
                // Get mouse position relative to the column.
                const y = e.clientY - pos.top;
                // Create container.
                const { element: container, start, end } = this.createAppointmentElement('new-appointment', y);
                container.classList.add('bg-gray-100');
                this.renderContainer.appendChild(container);
                // Set the slot time.
                this.setSlotTime(container, start, end);
                // Save the callback otherwise removeEventListener won't work.
                this.dragCb = (e) => this.handleDrag(e);
                this.dragEndCb = (e) => this.handleDragEnd(e);
                this.dragKeyCb = (e) => this.handleDragKeyEvent(e);

                document.addEventListener('mousemove', this.dragCb);
                document.addEventListener('mouseup', this.dragEndCb, { once: true });
                document.addEventListener('keydown', this.dragKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);

            // Check if there is already being a drag in the column
            // Otherwise the column is clicked.
            if (this.state.isColumnDragging) return;

            // !! Code here for a click !!
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    public handleAppointmentDragStart = async (e: MouseEvent, appointment: GetAppointmentResponse, el: HTMLDivElement): Promise<void> => {
        // Prevent other events from firing. E.g. the drag and select events.
        e.preventDefault();
        // Clone the appointment element.
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${appointment.id}`;
        // Make sure the clone is always on top.
        clone.style.width = '100%';
        clone.style.zIndex = '10000';
        clone.style.left = '0px';
        // Set the initial active container and index.
        this.activeContainer = this.renderContainer;
        this.activeIndex = this.index;
        // Add the clone to the active container.
        this.activeContainer.appendChild(clone);
    };

    public handleAppointmentDrag = (e: MouseEvent, appointment: GetAppointmentResponse): Promise<void> => {
        // Find the clone.
        const clone = document.querySelector(`#clone-${appointment.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        // Get the position of the active container.
        let pos = this.activeContainer.getBoundingClientRect();
        // Check if the user is dragging the appointment to the left or right side of the container.
        const isLeft = e.clientX < pos.x;
        const isRight = e.clientX > pos.x + pos.width;
        // When the user is dragging the appointment to the left or right side of the container
        // This can only be done when no user columns are rendered.
        if (isLeft || isRight) {
            // Decide which container we need to use.
            this.activeIndex = isLeft ? this.activeIndex - 1 : this.activeIndex + 1;
            if (this.activeIndex < 0) this.activeIndex = 0;
            if (this.activeIndex > this.columns.length - 1) this.activeIndex = this.columns.length - 1;

            const container = document.getElementById(`render-area-${this.activeIndex}`) as HTMLDivElement;
            if (isDefined(container)) {
                // We have a new container. Remove the clone from the old container.
                this.activeContainer.querySelector(`#clone-${appointment.id}`)?.remove();
                // Override the active container and position and add the clone to the new container.
                this.activeContainer = container;
                this.activeContainer.appendChild(clone);
                pos = container.getBoundingClientRect();
            }
        }
        // Just move the appointment up and down.
        const { percentage } = this.setPosition(this.activeContainer, clone, e.clientY);
        const { hours, minutes } = this.getTimeFromGridPosition(percentage);
        // Set the new start and end date.
        // Note the conversion from seconds to milliseconds.
        const duration = intervalToDuration({ start: 0, end: appointment.duration * 1000 });
        const newStart = setMinutes(setHours(this.date, hours), minutes);
        const newEnd = setMinutes(setHours(this.date, hours + duration.hours), minutes + duration.minutes);
        // Update the time slot.
        this.setSlotTime(clone, newStart, newEnd, clone.clientHeight);
    };

    public handleAppointmentDragEnd = async (_: MouseEvent, appointment: GetAppointmentResponse): Promise<void> => {
        const originalStart = appointment.start;
        const originalEnd = appointment.end;

        const original = document.querySelector(`#appointment-${appointment.id}`) as HTMLDivElement;
        const clone = document.querySelector(`#clone-${appointment.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        this.setLoading(original, true);
        // Get the date and update the slot in the original.
        const start = new Date(clone.getAttribute('data-start'));
        const end = new Date(clone.getAttribute('data-end'));

        const render = () => {
            this.appointments.sort(this.sortByStartDateAndDuration);
            this.render(this.appointments);

            // Let the appointments know that the appointment has changed
            // and they need to re-render.
            this.events.publish(CustomEvents.SchedulerAppointmentDragged, { columnDate: this.date, appointment });
        };

        // When the user moved the appointment to another column.
        if (this.index !== this.activeIndex) {
            // Copy the original appointment.
            const copy = GetAppointmentResponse.fromJS(cloneDeep(appointment));
            // Update the start and end date with the new column date.
            const columnDate = this.columns[this.activeIndex].date;
            // Get the hours and minutes from the originals start and end dates.
            const startData = { hours: getHours(start), minutes: getMinutes(start) };
            const endData = { hours: getHours(end), minutes: getMinutes(end) };
            // Set the new start and end date by using the column date and the hours and minutes from the original appointment.
            copy.start = setMinutes(setHours(columnDate, startData.hours), startData.minutes);
            copy.end = setMinutes(setHours(columnDate, endData.hours), endData.minutes);

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the appointment date
            // and not the current date because the appointment date
            // can be in a different timezone that the current date.
            const timezoneOffset = copy.start.getTimezoneOffset();

            // We are booking for a room instead of a user.
            if (isDefined(this.wrapper)) {
                const wrapper = this.columns[this.activeIndex].wrapper;

                const response = await this.schedulesApi.getByRoomAndTimestamps(this.workspace, wrapper.room.id, copy.start, copy.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-appointment-failed.title'), //
                        this.t.tr(`translation:partial-views.appointments.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    this.setLoading(original, false);
                    clone.remove();
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                copy.createOutsideSchedule = false;

                // Update the appointment data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                copy.location = location ?? copy.location;
                copy.schedule = response.schedule ?? copy.schedule;
                copy.examinationRoom = wrapper?.room ?? copy.examinationRoom;
                copy.practitioner = response.user ?? copy.practitioner;
            } else {
                // We moved to a different column. Find the user of that column and
                // 1) Update the schedule.
                // 2) Update the examination room.
                // 2) Update the location.
                // 3) Update the practitioner.
                const user = this.hasUserFilter ? this.columns[this.activeIndex].user : this.user;
                const response = await this.schedulesApi.getByPractitionerAndTimestamps(this.workspace, user.id, copy.start, copy.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-appointment-failed.title'), //
                        this.t.tr(`translation:partial-views.appointments.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    clone.remove();
                    this.setLoading(original, false);
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                copy.createOutsideSchedule = false;

                // Update the appointment data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                copy.location = location ?? copy.location;
                copy.schedule = response.schedule ?? copy.schedule;
                copy.examinationRoom = response.room ?? copy.examinationRoom;
                copy.practitioner = isDefined(user)
                    ? new UserEntityReference({
                          id: user.id,
                          name: user.displayName
                      })
                    : copy.practitioner;
            }

            const doIt = async () => {
                // Update the appointment
                const { success } = await this.updateAppointment('move', copy, clone);
                if (!success) {
                    this.setLoading(original, false);
                    // Make sure the original time slots are restored
                    copy.start = originalStart;
                    copy.end = originalEnd;
                    copy.duration = differenceInSeconds(originalEnd, originalStart);
                    this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                    clone.remove();
                    return;
                }

                // Add the appointment to the new list.
                const appointments = [...this.columns[this.activeIndex].appointments, copy];
                this.columns[this.activeIndex].render(appointments);
                this.columns[this.activeIndex].appointments = appointments;
                // Remove original appointment from the list.
                const index = this.appointments.findIndex((x) => x.id === appointment.id);
                this.appointments.splice(index, 1);
                // Remove the clone.
                clone.remove();

                render();
            };

            if (
                (this.hasUserFilter && isDefined(this.user) && copy.practitioner.id !== this.user.id) || //
                (isDefined(this.wrapper) && copy.examinationRoom.id !== this.wrapper.room.id)
            ) {
                const title = isDefined(this.user)
                    ? this.t.tr('translation:partial-views.scheduler.questions.change-practitioner.title')
                    : this.t.tr('translation:partial-views.scheduler.questions.change-room.title');

                const message = isDefined(this.user)
                    ? this.t
                          .tr('translation:partial-views.scheduler.questions.change-practitioner.message') //
                          .replace('{user1}', `<strong>${this.user.displayName}</strong>`)
                          .replace('{user2}', `<strong>${copy.practitioner.name}</strong>`)
                    : this.t
                          .tr('translation:partial-views.scheduler.questions.change-room.message') //
                          .replace('{room1}', `<strong>${this.wrapper.room.name[this.language]} (${this.wrapper.location.name[this.language]})</strong>`)
                          .replace('{room2}', `<strong>${copy.examinationRoom.name[this.language]} (${copy.location.name[this.language]})</strong>`);

                await this.modalService.confirm(
                    new ConfirmationOptions({
                        title,
                        message,
                        type: 'warning',
                        btnOk: this.t.tr('translation:global.buttons.continue'),
                        callback: async (confirmed: boolean): Promise<void> => {
                            if (confirmed) await doIt();
                            else {
                                clone.remove();
                                render();
                            }
                        }
                    })
                );
            } else
                await this.modalService.confirm(
                    new ConfirmationOptions({
                        title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                        message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                        type: 'warning',
                        btnOk: this.t.tr('translation:global.buttons.continue'),
                        callback: async (confirmed: boolean): Promise<void> => {
                            if (confirmed) await doIt();
                            else {
                                clone.remove();
                                render();
                            }
                        }
                    })
                );
        } else {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.continue'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            const index = this.appointments.findIndex((x) => x.id === appointment.id);
                            this.appointments[index].start = start;
                            this.appointments[index].end = end;

                            // Update the appointment
                            const { success } = await this.updateAppointment('move', appointment, clone);
                            if (!success) {
                                this.setLoading(original, false);
                                // Make sure the original time slots are restored.
                                this.appointments[index].start = originalStart;
                                this.appointments[index].end = originalEnd;
                                this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                                clone.remove();
                                return;
                            }
                            // Because the clone doesn't have the required event listeners we copy
                            // the position of the clone to the original.
                            original.style.top = clone.style.top;
                            original.style.left = clone.style.left;
                            original.style.width = clone.style.width;
                            // Update the slot time.
                            this.setSlotTime(original, start, end, original.clientHeight);
                            // Remove the clone
                            clone.remove();
                            // Reset top difference for the next appointment.
                            this.differenceBetweenMouseAndTopOfClone = null;
                            this.setLoading(original, false);
                        } else clone.remove();

                        render();
                    }
                })
            );
        }
    };

    public handleAppointmentDragCanceled = async (e: KeyboardEvent, appointment: GetAppointmentResponse): Promise<void> => {
        if (e.key === 'Escape') {
            this.state.isAppointmentDragging = false;
            document.querySelector(`#clone-${appointment.id}`)?.remove();
        }
    };

    public handleAppointmentResizeStart = async (e: MouseEvent, _: 'top' | 'bottom', appointment: GetAppointmentResponse, el: HTMLDivElement): Promise<void> => {
        // Prevent other events from firing. Like the drag and select event.
        e.preventDefault();
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${appointment.id}`;
        // Make sure the clone is always on top.
        clone.style.width = '100%';
        clone.style.zIndex = '10000';
        clone.style.left = '0px';

        this.renderContainer.appendChild(clone);

        this.originalHeight = parseFloat(getComputedStyle(clone, null).getPropertyValue('height').replace('px', ''));
        this.originalY = e.clientY;
        this.originalTop = parseInt(document.defaultView.getComputedStyle(clone).top, 10);
    };

    public handleAppointmentResize = async (e: MouseEvent, side: 'top' | 'bottom', appointment: GetAppointmentResponse): Promise<void> => {
        const clone = this.renderContainer.querySelector(`#clone-${appointment.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;

        let heightInPixels = 0;

        const content = clone.querySelector('[data-type="content"]') as HTMLDivElement;
        if (side === 'top') {
            // Calculate the new height and top position for the element
            const newHeight = this.originalHeight - (e.pageY - this.originalY);

            let newTop = this.originalTop + (e.pageY - this.originalY);
            // Dont allow scrolling above the top of the container
            // or let the appointment be smaller than 22px.
            if (newTop < 0 || newHeight < 22) return;
            heightInPixels = newHeight;

            // Adjust padding top based on the height.
            if (newHeight <= 25) content.style.paddingTop = '2px';
            else if (newHeight < 44) content.style.paddingTop = '5px';
            else content.style.paddingTop = '12px';

            // Snap values to the
            const top = this.snapToGrid(this.renderContainer, newTop);
            const height = this.snapToGrid(this.renderContainer, newHeight);
            clone.style.height = `${height}%`;
            clone.style.top = `${top}%`;
            // Note, changing this to percentage values will make the appointment jump when resizing.
            // clone.style.height = `${newHeight}px`;
            // clone.style.top = `${newTop}px`;
            const { hours, minutes } = this.getTimeFromGridPosition(top);
            const newStart = setMinutes(setHours(this.date, hours), minutes);
            this.setSlotTime(clone, newStart, appointment.end, heightInPixels);
        } else {
            const pos = clone.getBoundingClientRect();
            // Set new height and calculate the new end time.
            let height = e.pageY - pos.top;
            // Prevent the height from being smaller than 22px.
            if (height < 22) height = 22;
            heightInPixels = height;

            // Adjust padding top based on the height.
            if (height <= 22) content.style.paddingTop = '2px';
            else if (height < 44) content.style.paddingTop = '5px';
            else content.style.paddingTop = '12px';

            // Convert to percentage.
            let snapped = this.snapToGrid(this.renderContainer, height);
            // Make sure the appointment doesn't go over the bottom of the container.
            const elStartPercentage = Number(clone.style.top.replace('%', ''));
            if (snapped + elStartPercentage > 100) return;
            // Update the time slot.
            const { hours, minutes } = this.getTimeFromGridPosition(elStartPercentage + snapped);
            // Set the end time and update the slot times.
            const newEnd = setMinutes(setHours(this.date, hours), minutes);
            this.setSlotTime(clone, appointment.start, newEnd, heightInPixels);
            // Set new height.
            clone.style.height = `${snapped}%`;
        }
    };

    public handleAppointmentResizeEnd = async (_: MouseEvent, __: 'top' | 'bottom', appointment: GetAppointmentResponse): Promise<void> => {
        const originalStart = appointment.start;
        const originalEnd = appointment.end;

        const original = this.renderContainer.querySelector(`#appointment-${appointment.id}`) as HTMLDivElement;
        const clone = this.renderContainer.querySelector(`#clone-${appointment.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.continue'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Get the date and update the slot in the original.
                        const start = new Date(clone.getAttribute('data-start'));
                        const end = new Date(clone.getAttribute('data-end'));

                        const index = this.appointments.findIndex((x) => x.id === appointment.id);
                        this.appointments[index].start = start;
                        this.appointments[index].end = end;
                        this.appointments[index].duration = differenceInSeconds(end, start);

                        // Update the appointment
                        const { success } = await this.updateAppointment('resize', this.appointments[index], clone);
                        if (!success) {
                            // Make sure the original time slots are restored
                            this.appointments[index].start = originalStart;
                            this.appointments[index].end = originalEnd;
                            this.appointments[index].duration = differenceInSeconds(originalEnd, originalStart);
                            this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                            clone.remove();
                            return;
                        }

                        this.setSlotTime(original, start, end, original.clientHeight);
                        this.appointments.sort(this.sortByStartDateAndDuration);
                        this.render(this.appointments);

                        // Remove the clone
                        clone.remove();

                        // Let the appointments know that the appointment has changed
                        // and they need to re-render.
                        this.events.publish(CustomEvents.SchedulerAppointmentResized, { columnDate: this.date, appointment });

                        this.setLoading(original, false);
                    } else {
                        // Remove the clone
                        clone.remove();
                        this.setLoading(original, false);
                    }
                }
            })
        );
    };

    public handleAppointmentResizeCanceled = async (e: KeyboardEvent, side: 'top' | 'bottom', appointment: GetAppointmentResponse): Promise<void> => {
        if (e.key === 'Escape') {
            this.state.isAppointmentResizing = false;
            this.renderContainer.querySelector(`#clone-${appointment.id}`)?.remove();
        }
    };

    public generateScheduleName(
        item: {
            schedule: GetScheduleResponse;
            slotsAndRooms: {
                slot: ScheduleTimeSlot;
                room: ExaminationRoom;
            }[];
        },
        slotAndRoom: {
            slot: ScheduleTimeSlot;
            room: ExaminationRoom;
        }
    ): string {
        let name = '';
        const converter = new FormatTimeValueConverter();

        if (isDefined(this.wrapper)) {
            name += slotAndRoom.slot.practitioner.name;
        } else if (isNotDefined(this.wrapper) && isDefined(item.schedule.location.data[this.language])) {
            name += `${item.schedule.location.data[this.language]}`;
        } else if (isNotDefined(this.wrapper) && isNotDefined(item.schedule.location.data[this.language])) {
            name += `${item.schedule.location.translations[this.language]}`;
        }

        name += ` • ${slotAndRoom.room.name[this.language]} • ${converter.toView(slotAndRoom.slot.start)} - ${converter.toView(slotAndRoom.slot.end)}`;

        return name;
    }

    public generateStylingForScheduleSlot(slot: ScheduleTimeSlot): { height: string; top: string; heightNumber: number } {
        const day = toDayOfWeek(this.date);
        const start = dayAndTimeToDate(day, slot.start);
        const end = dayAndTimeToDate(day, slot.end);
        const duration = intervalToDuration({ start, end });
        const durationInSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;

        // First calculate the total amount of slots and the percentage per slot.
        const totalAmountOfSlots = getTotalAmountOfSlots(start, this.settings);
        const percentagePerSlot = 100 / totalAmountOfSlots;

        const getStartingPosition = (start: Date): number => {
            // First get the duration from the start of the day to the start of the appointment.
            const durationBeforeStart = intervalToDuration({
                start: setMinutes(setHours(start, this.settings.start.hour), this.settings.start.minute),
                end: start
            });
            // Calculate how many slots we need for the duration before the start of the appointment.
            const slotsForTheHours = durationBeforeStart.hours * (60 / this.settings.slotSize);
            const slotsForTheMinutes = durationBeforeStart.minutes / this.settings.slotSize;
            // Calculate the percentage for the duration before the start of the appointment.
            return (slotsForTheHours + slotsForTheMinutes) * percentagePerSlot;
        };

        const getHeight = (): number => {
            let durationInMinutes = secondsToMinutes(durationInSeconds);

            // First calculate how many blocks the appointment takes by first parsing the duration (which is in seconds) to minutes.
            // Then devide the duration in minuts it by the minutes per block.
            let height =
                (durationInMinutes / this.settings.slotSize) *
                // Then multiply the amount of blocks by the percentage to get the height for the appointment in percentage.
                percentagePerSlot;

            // We need to check if the height doesn't exceed the total height of the column.
            // If it does, we need to set the height to the maximum height.
            if (startPosition + height > 100) {
                // We calculate the height by subtracting the start percentage from the total height of the column.
                height = 100 - startPosition;
            }

            return height;
        };

        const startPosition = getStartingPosition(start);
        const height = getHeight();

        return {
            heightNumber: height,
            height: `${height}%`, //
            top: `${startPosition}%`
        };
    }

    private async handlePaste(start: Date): Promise<void> {
        if (isNotDefined(this.state.cut)) return;
        this.state.isSaving = true;

        // Update the start and end to the new dates (positions).
        const appointmentToUpdate = this.state.cut.appointment;

        const doIt = async () => {
            // Update the start and end to the new dates (positions) so that we can find a new schedule later on.
            appointmentToUpdate.start = setMinutes(setHours(start, getHours(start)), getMinutes(start));
            appointmentToUpdate.end = addSeconds(appointmentToUpdate.start, appointmentToUpdate.duration);

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the appointment date
            // and not the current date because the appointment date
            // can be in a different timezone that the current date.
            const timezoneOffset = appointmentToUpdate.start.getTimezoneOffset();

            // We are booking for a room instead of a user.
            if (isDefined(this.wrapper)) {
                const response = await this.schedulesApi.getByRoomAndTimestamps(this.workspace, this.wrapper.room.id, appointmentToUpdate.start, appointmentToUpdate.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-appointment-failed.title'), //
                        this.t.tr(`translation:partial-views.appointments.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                appointmentToUpdate.createOutsideSchedule = false;

                // Update the appointment data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                appointmentToUpdate.location = location ?? appointmentToUpdate.location;
                appointmentToUpdate.schedule = response.schedule ?? appointmentToUpdate.schedule;
                appointmentToUpdate.examinationRoom = this.wrapper?.room ?? appointmentToUpdate.examinationRoom;
                appointmentToUpdate.practitioner = response.user ?? appointmentToUpdate.practitioner;
            } else {
                // We pasted to possible a column of a different user. So:
                // 1) Update the schedule.
                // 2) Update the examination room.
                // 2) Update the location.
                // 3) Update the practitioner.
                const response = await this.schedulesApi.getByPractitionerAndTimestamps(this.workspace, this.user.id, appointmentToUpdate.start, appointmentToUpdate.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-appointment-failed.title'), //
                        this.t.tr(`translation:partial-views.appointments.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                appointmentToUpdate.createOutsideSchedule = false;

                // Update the appointment data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                appointmentToUpdate.location = location ?? appointmentToUpdate.location;
                appointmentToUpdate.schedule = response.schedule ?? appointmentToUpdate.schedule;
                appointmentToUpdate.examinationRoom = response.room ?? appointmentToUpdate.examinationRoom;
                appointmentToUpdate.practitioner = isDefined(this.user)
                    ? new UserEntityReference({
                          id: this.user.id,
                          name: this.user.displayName
                      })
                    : appointmentToUpdate.practitioner;
            }

            // Update the appointment
            const { success, appointment } = await this.updateAppointment('paste', appointmentToUpdate, null);
            if (!success) {
                this.state.isSaving = false;
                return;
            }

            // Remove original appointment from the its original column.
            // If we cut from a full calendar and paste it to a column when a user filter is selected
            // the column index could not exist anymore.
            if (isDefined(this.columns[this.state.cut.columnIndex])) {
                const index = this.columns[this.state.cut.columnIndex].appointments.findIndex((x) => x.id === appointment.id);
                // Only remove the appointment if it exists.
                if (index > -1) {
                    this.columns[this.state.cut.columnIndex].appointments.splice(index, 1);
                    this.events.publish(CustomEvents.SchedulerAppointmentPasted, { columnDate: this.columns[this.state.cut.columnIndex].date, appointment });
                }
            }

            // Add the appointment to the current column and render the column and appointments.
            const appointments = [...this.appointments, appointment];
            this.render(appointments);
            this.events.publish(CustomEvents.SchedulerAppointmentPasted, { columnDate: this.date, appointment });

            // Reset cut.
            this.state.isSaving = false;
            this.state.cut = null;
        };

        if (
            (isDefined(this.user) && appointmentToUpdate.practitioner.id !== this.user.id) || //
            (isDefined(this.wrapper) && appointmentToUpdate.examinationRoom.id !== this.wrapper.room.id)
        ) {
            const title = isDefined(this.user)
                ? this.t.tr('translation:partial-views.scheduler.questions.change-practitioner.title')
                : this.t.tr('translation:partial-views.scheduler.questions.change-room.title');

            const message = isDefined(this.user)
                ? this.t
                      .tr('translation:partial-views.scheduler.questions.change-practitioner.message') //
                      .replace('{user1}', `<strong>${appointmentToUpdate.practitioner.name}</strong>`)
                      .replace('{user2}', `<strong>${this.user.displayName}</strong>`)
                : this.t
                      .tr('translation:partial-views.scheduler.questions.change-room.message') //
                      .replace('{room1}', `<strong>${appointmentToUpdate.examinationRoom.name[this.language]} (${appointmentToUpdate.location.name[this.language]})</strong>`)
                      .replace('{room2}', `<strong>${this.wrapper.room.name[this.language]} (${this.wrapper.location.name[this.language]})</strong>`);

            await this.modalService.confirm(
                new ConfirmationOptions({
                    title,
                    message,
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.continue'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) doIt();
                        else this.state.isSaving = false;
                    }
                })
            );
        } else doIt();
    }

    private handleDrag(e: MouseEvent): void {
        if (this.state.isColumnDragging) {
            const element = document.getElementById('new-appointment') as HTMLDivElement;
            if (isNotDefined(element)) return;

            const start = new Date(element.getAttribute('data-start'));
            const end = new Date(element.getAttribute('data-end'));

            const side: 'top' | 'bottom' = 'bottom' as any;

            if (side === 'top') {
                // Calculate the new height and top position for the element
                // const newHeight = this.originalHeight - (e.pageY - this.originalY);
                // let newTop = this.originalTop + (e.pageY - this.originalY);
                // // Dont allow scrolling above the top of the container
                // // or let the appointment be smaller than 50px.
                // if (newTop < 0 || newHeight < 50) return;
                // // Snap values to the
                // const top = this.snapToGrid(this.renderContainer, newTop);
                // const height = this.snapToGrid(this.renderContainer, newHeight);
                // element.style.height = `${height}%`;
                // element.style.top = `${top}%`;
                // // Note, changing this to percentage values will make the appointment jump when resizing.
                // // clone.style.height = `${newHeight}px`;
                // // clone.style.top = `${newTop}px`;
                // const { hours, minutes } = this.getTimeFromGridPosition(top);
                // const newStart = setMinutes(setHours(this.date, hours), minutes);
                // this.setSlotTime(element, newStart, appointment.end);
            } else {
                const pos = element.getBoundingClientRect();
                // Set new height and calculate the new end time.
                let height =
                    e.pageY +
                    // Make sure the hover appointment is not triggering.
                    10 -
                    pos.top;

                // Adjust padding top based on the height.
                if (height < 30) element.style.paddingTop = '2px';
                else if (height < 44) element.style.paddingTop = '5px';
                else element.style.paddingTop = '12px';

                // Prevent the height from being smaller than 22px.
                if (height < 22) height = 22;
                // Convert to percentage.
                let snapped = this.snapToGrid(this.renderContainer, height);
                // Make sure the appointment doesn't go over the bottom of the container.
                const elStartPercentage = Number(element.style.top.replace('%', ''));
                if (snapped + elStartPercentage > 100) return;
                // Update the time slot.
                const { hours, minutes } = this.getTimeFromGridPosition(elStartPercentage + snapped);
                // Set the end time and update the slot times.
                const newEnd = setMinutes(setHours(this.date, hours), minutes);
                this.setSlotTime(element, start, newEnd);
                // Set new height.
                element.style.height = `${snapped}%`;
            }
        }
    }

    private async handleDragEnd(e: MouseEvent): Promise<void> {
        this.state.isColumnDragging = false;
        // Get the new appoint element.
        const element = document.getElementById('new-appointment') as HTMLDivElement;
        // Get the start and end dates from the element.
        const start = new Date(element.getAttribute('data-start'));
        const end = new Date(element.getAttribute('data-end'));
        // Create a new appointment.
        const { success, appointment } = await this.createAppointment(start, end, element);
        if (!success) {
            element.remove();
            return;
        }

        // Render the appoints based on a new list including the new appointment.
        // This way the layer settings are ready when the appointment is added.
        // Though, only add the appointment if it doesn't exist yet.
        // The Broadcasts.AppointmentCreated event will also be triggered when the appointment
        // is added to the list. This can create a race condition and a duplicate appointment.
        if (this.appointments.findIndex((x) => x.id === appointment.id) === -1) {
            const appointments = [...this.appointments, appointment];
            this.render(appointments);
        }

        // Remove the new appointment element.
        element.remove();
        // Let the appointments know that the appointments have changed
        // and they need to re-render.
        this.events.publish(CustomEvents.SchedulerAppointmentCreated, { columnDate: this.date, appointment });
    }

    private async handleDragKeyEvent(e: KeyboardEvent): Promise<void> {
        if (e.key === 'Escape') {
            this.state.isColumnDragging = false;
            document.getElementById('new-appointment')?.remove();
            document.removeEventListener('mousemove', this.dragCb);
            document.removeEventListener('mouseup', this.dragEndCb);
            document.removeEventListener('keydown', this.dragKeyCb);
        }
    }

    private async updateAppointment(
        kind: 'move' | 'resize' | 'paste',
        appointment: GetAppointmentResponse,
        element: HTMLDivElement
    ): Promise<{ success: boolean; appointment: GetAppointmentResponse }> {
        if (isDefined(element)) this.setLoading(element, true);
        this.state.isSaving = true;
        return new Promise((resolve) => {
            setTimeout(async () => {
                if (kind === 'move') {
                    if (isFunction(this.onAppointmentMove)) {
                        const response = await this.onAppointmentMove(appointment, appointment.start, appointment.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else if (kind === 'resize') {
                    if (isFunction(this.onAppointmentResize)) {
                        const response = await this.onAppointmentResize(appointment, appointment.start, appointment.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else if (kind === 'paste') {
                    if (isFunction(this.onAppointmentResize)) {
                        const response = await this.onAppointmentResize(appointment, appointment.start, appointment.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else resolve({ success: false, appointment: null });
            }, 500);
        });
    }

    private async createAppointment(start: Date, end: Date, element: HTMLDivElement): Promise<{ success: boolean; appointment: GetAppointmentResponse }> {
        // this.state.isSaving = true;
        this.setLoading(element, true);
        return new Promise(async (resolve) => {
            if (isFunction(this.onAppointmentCreate)) {
                const userId = isNotDefined(this.wrapper) ? this.user.id : null;
                const roomId = isDefined(this.wrapper) ? this.wrapper.room.id : null;
                const locationId = isDefined(this.wrapper) ? this.wrapper.location.id : null;
                const response = await this.onAppointmentCreate(start, end, userId, roomId, locationId);
                this.state.isSaving = false;
                resolve(response);
            } else {
                this.state.isSaving = false;
                resolve({ success: false, appointment: null });
            }
        });
    }

    private setLoading(el: HTMLDivElement, load: boolean): void {
        if (load) el.classList.add('loading');
        else el.classList.remove('loading');
    }

    private snapToGrid(container: HTMLDivElement, y: number): number {
        if (isNotDefined(container)) return 0;
        // Get the height of the container.
        const containerHeight = container.clientHeight;
        // Calculate the percentage value of the y (mouse) position.
        const percentageValue = (y / containerHeight) * 100;
        // Calculate the grid snap value by rounding the percentage value to the nearest grid snap value.
        return Math.round(percentageValue / this.gridSnap) * this.gridSnap;
    }

    private getTimeFromGridPosition(y: number): { hours: number; minutes: number } {
        // Calculate the total minutes based on the grid position
        const minutes = Math.round((y / this.gridSnap) * this.settings.slotSize);
        // Calculate the total minutes from the start time and the grid position
        const totalMinutes = this.settings.start.hour * 60 + this.settings.start.minute + minutes;
        // Calculate hours and remaining minutes
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;

        return { hours, minutes: remainingMinutes };
    }

    private setSlotTime(element: HTMLDivElement, start: Date, end?: Date, height: number = -1): void {
        const slot = element.querySelector('[data-type="slot"]') as HTMLElement;

        // Only show the slot above certain height.
        if (height != -1 && height < 60) {
            slot.style.display = 'none';
        } else slot.style.display = 'flex';

        // Always update the slot times
        element.setAttribute('data-start', formatISO(start));
        if (isDefined(end)) {
            slot.innerHTML = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;
            element.setAttribute('data-end', formatISO(end));
        } else slot.innerHTML = `${format(start, 'HH:mm')}`;
    }

    private setPosition(container: HTMLDivElement, element: HTMLDivElement, y: number): { top: number; percentage: number } {
        // Get the position of the container.
        const containerPos = container.getBoundingClientRect();
        const elementPos = element.getBoundingClientRect();

        // Calculate top difference between the mouse position and the top of the clone position.
        if (isNotDefined(this.differenceBetweenMouseAndTopOfClone)) this.differenceBetweenMouseAndTopOfClone = y - elementPos.top;

        // Get the position of the mouse relative to the container.
        let top =
            y -
            containerPos.top -
            // Remove the difference between the mouse and the top of the clone.
            // This way it doesn't matter where you click on the appointment and
            // it prevents the appointment from jumping when you start dragging.
            this.differenceBetweenMouseAndTopOfClone -
            // Keeps the mouse above the clone container so that the cursor stays on the move icon.
            5;

        // The clone can not be outside of the container (top side)
        if (top < 0) top = 0;
        // The clone can not be outside of the container (bottom side)
        // Make to use offsetHeight because the element can have a border.
        if (top + element.offsetHeight > this.height) top = this.height - element.offsetHeight;

        // Make sure the clone snaps to the grid.
        const percentage = this.snapToGrid(container, top);
        // Set the position of the clone, by snapping to the grid.
        element.style.top = `${percentage}%`;
        // The clone should always stay in the left side of the container.
        element.style.left = `${0}px`;
        return { top, percentage };
    }

    private render(appointments: GetAppointmentResponse[]): void {
        // Sort first by start date and then by duration.
        appointments.distinct((x: GetAppointmentResponse) => x.id).sort(this.sortByStartDateAndDuration);

        // Lets loop through each appointment and find the ones that overlap.
        for (let index = 0; index < appointments.length; index++) {
            const appointment = appointments[index];
            // Reset layer
            this.layers[appointment.id] = { zIndex: 1 };
            // Check if this appointment overlaps with any other appointments.
            const overlaps = appointments.filter((a) => this.checkIfOverlaps(appointment, a));
            // Check if the overlaps come before the current appointment.
            // Note that the appointments are already sorted by start date.
            // If the overlap comes before the current appointment, we need to
            // increase the z-index.
            const overlapsThatComeBeforeCurrentApointment = overlaps.filter((overlap) => {
                const overlapIndex = appointments.findIndex((a) => a.id === overlap.id);
                return overlapIndex < index;
            });

            const zIndex = overlapsThatComeBeforeCurrentApointment.any()
                ? // If we have overlaps use the biggest z-index + 1.
                  Math.max(...overlapsThatComeBeforeCurrentApointment.map((x) => this.layers[x.id]?.zIndex)) + 1
                : // Otherwise use 0.
                  0;

            this.layers[appointment.id] = { zIndex };
        }

        this.appointments = appointments;
    }

    private checkIfOverlaps(ap1: GetAppointmentResponse, ap2: GetAppointmentResponse): boolean {
        // Don't compare with self.
        if (ap1.id === ap2.id) return false;

        return areIntervalsOverlapping(
            { start: ap1.start, end: ap1.end }, //
            { start: ap2.start, end: ap2.end }
        );
    }

    private sortByStartDateAndDuration = (a: GetAppointmentResponse, b: GetAppointmentResponse): number => {
        let startA = a.start;
        let startB = b.start;

        if (startA.getTime() > startB.getTime()) return 1;
        else if (startA.getTime() < startB.getTime()) return -1;

        // Sort on duration
        return b.duration - a.duration;
    };

    private createAppointmentElement(id: string, y: number): { element: HTMLDivElement; start: Date; end: Date } {
        const top = this.snapToGrid(this.renderContainer, y);
        // Find the start time based on the mouse position.
        const { hours, minutes } = this.getTimeFromGridPosition(top);
        const element = document.createElement('div');

        const start = setMinutes(setHours(this.date, hours), minutes);
        const end = setMinutes(setHours(this.date, hours), minutes + this.settings.slotSize);

        // Set the start and end time.
        element.setAttribute('data-start', formatISO(start));
        element.setAttribute('data-end', formatISO(end));

        const spinner = document.createElement('ux-spinner');
        spinner.setAttribute('size', 'xs');

        const spinnerContainer = document.createElement('div');
        spinnerContainer.className = 'absolute z-50 group-[&.loading]:flex top-0 left-0 w-full h-full bg-white opacity-25 rounded-xl hidden justify-center items-center';
        spinnerContainer.appendChild(spinner);

        // Create the slot label.
        const slot = document.createElement('p');
        slot.className = 'text-xs text-gray-800';
        slot.setAttribute('data-type', 'slot');

        // Create the slot wrapper and container.
        const wrapper = document.createElement('div');
        wrapper.className = 'relative flex flex-col flex-1 px-4';

        // Style the container.
        element.id = id;
        element.className = 'absolute flex flex-col h-full transition duration-300 ease-in-out border-2 border-white group rounded-xl';
        element.style.zIndex = '10000';
        element.style.width = '100%';
        element.style.paddingTop = '1px';
        element.style.minHeight = '22px';
        element.style.height = `${this.gridSnap}%`;
        element.style.top = `${top}%`;

        wrapper.appendChild(spinnerContainer);
        wrapper.appendChild(slot);
        element.appendChild(wrapper);

        return { element, start, end };
    }

    private setBaseLoaders(): void {
        if (this.index === 0) this.loader = { amount: 2, top: [2, 25], height: [15, 35] };
        else if (this.index === 1) this.loader = { amount: 1, top: [10], height: [15] };
        else if (this.index === 2) this.loader = { amount: 3, top: [3, 38, 55], height: [10, 8, 9] };
        else if (this.index === 3) this.loader = { amount: 1, top: [15], height: [20] };
        else if (this.index === 4) this.loader = { amount: 2, top: [40, 55], height: [12, 5] };
        else if (this.index === 5) this.loader = { amount: 2, top: [1, 30], height: [20, 12] };
        else if (this.index === 6) this.loader = { amount: 1, top: [10], height: [45] };
        else if (this.index === 7) this.loader = { amount: 2, top: [40, 55], height: [12, 5] };
        else if (this.index === 8) this.loader = { amount: 1, top: [10], height: [15] };
        else if (this.index === 9) this.loader = { amount: 1, top: [15], height: [20] };
    }

    private async init(): Promise<void> {
        try {
            this.baseLoaded = false;

            const day = toDayOfWeek(this.date);
            const [appointments, scheduleResponse] = await Promise.all([
                this.appointmentsApi.search(
                    this.workspace, //
                    '',
                    100,
                    0,
                    undefined,
                    undefined,
                    undefined,
                    startOfDay(this.date),
                    endOfDay(this.date),
                    undefined,
                    isDefined(this.user) ? [this.user.id] : undefined,
                    isDefined(this.wrapper) ? [this.wrapper.room.id] : undefined
                ),
                this.hasRole(UserRoles.ReadSchedules)
                    ? isDefined(this.wrapper)
                        ? this.schedulesApi.getByRoomAndTimestamp(this.workspace, this.wrapper.room.id, this.date, day)
                        : this.schedulesApi.getByPractitionerAndTimestamp(this.workspace, this.user?.id, this.date, day)
                    : null
            ]);

            this.schedules = scheduleResponse as any;

            // Render the appointments.
            this.render(appointments.data);

            setTimeout(() => (this.baseLoaded = true), 500);
        } catch (e) {
            this.errorHandler.handle('BxSchedulerColumn.attached', e);
        }
    }
}
