import { GetUserResponse } from '@wecore/sdk-core';
import { GetHealthcareTaskResponse, HealthcareTasksApiClient } from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { isFunction, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../infra/events';
import { cloneDeep } from '../../infra/utilities';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';

@containerless
@inject(HealthcareTasksApiClient, IEventAggregator)
export class BxSchedulerTasksColumn {
    @bindable() public settings: SchedulerSettings;
    @bindable() public date: Date;
    @bindable() public user: GetUserResponse;
    @bindable() public wrapper: SchedulerRoomWrapper;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public authenticatedUser: GetUserResponse;
    @bindable() public onTaskClick: (task: GetHealthcareTaskResponse) => Promise<GetHealthcareTaskResponse>;

    public myTasks: GetHealthcareTaskResponse[];
    public tasks: GetHealthcareTaskResponse[];
    public baseLoaded: boolean = false;
    private subscriptions: IDisposable[];

    public constructor(
        private tasksApi: HealthcareTasksApiClient, //
        private events: IEventAggregator
    ) {}

    public async bound() {
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerDateChanged, () => {
                // Allow the date to update before loading new tasks.
                setTimeout(() => this.loadTasks());
            })
        ];
        this.loadTasks();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }

    public async handleTaskSelect(task: GetHealthcareTaskResponse, index: number, personal: boolean): Promise<void> {
        if (isFunction(this.onTaskClick)) {
            const response = await this.onTaskClick(task);
            if (isNotDefined(response)) return;

            if (personal) {
                this.myTasks[index] = response;
                this.myTasks = [
                    ...(this.myTasks.length > 0 ? [this.myTasks.shift()] : []), //
                    ...cloneDeep(this.myTasks)
                ];
            } else {
                this.tasks[index] = response;
                this.tasks = [
                    ...(this.tasks.length > 0 ? [this.tasks.shift()] : []), //
                    ...cloneDeep(this.tasks)
                ];
            }
        }
    }

    private async loadTasks() {
        this.baseLoaded = false;
        const [myTasks, tasks] = await Promise.all([
            this.tasksApi.getPersonal(this.workspace, '', 100, 0, undefined, undefined, undefined, false, this.date),
            this.tasksApi.search(this.workspace, '', 100, 0, undefined, undefined, undefined, undefined, undefined, undefined, false, this.date)
        ]);

        this.myTasks = myTasks.data;
        this.tasks = tasks.data;

        setTimeout(() => (this.baseLoaded = true), 250);
    }
}
