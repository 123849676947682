export class CustomEvents {
    public static UserAvatarChanged = 'users:changed:avatar';
    public static LegalDocumentSigned = 'workspaces:legal:signed';
    public static ExaminationStepSettingsChanged = 'examinations:settings:changed';
    public static ExaminationStepAnswerChanged = 'examinations:steps:answered';
    public static ExaminationStepAnswerCopy = 'examinations:steps:copy';
    public static ExaminationReFlatten = 'examinations:reflatten';
    public static ExaminationActionStepChangedHeight = 'examinations:actions:steps:changed:height';
    public static ExaminationActionCategoryChangedExpanded = 'examinations:actions:categories:changed:expanded';
    public static ExaminationTherapyCategoryChangedExpanded = 'examinations:therapies:categories:changed:expanded';
    public static ExaminationEvaluateDDSettings = 'examinations:evaluate:ddsettings';
    public static ExaminationResultsChanged = 'examinations:results:changed';
    public static QuestionnaireItemsChanged = 'questionnaire:items:changed';
    public static ActionStepsChanged = 'action:steps:changed';
    public static ActionCategoryChanged = 'action:categories:changed';
    public static TherapyStepsChanged = 'therapy:steps:changed';
    public static TherapyCategoryChanged = 'therapy:categories:changed';
    public static MedicalRecordsCourseOfComplaintsChanged = 'medical-records:course-of-complaints:changed';
    public static MedicalRecordUpdated = 'medical-records:changed';
    public static MedicalRecordPhaseChange = 'medical-records:phases:change';
    public static MedicalRecordTreatmentStarted = 'medical-records:treatement:started';
    public static PatientUpdated = 'patients:updated';
    public static AccountUpdated = 'accounts:updated';
    public static ContactUpdated = 'contacts:updated';
    public static NavigationButtonPressed = 'navigation:buttons:back';
    public static NavigationFowardButtonPressed = 'navigation:buttons:forward';
    public static EmailSent = 'emails:sent';
    public static SchedulerAppointmentCreated = 'scheduler:appointments:created';
    public static SchedulerAppointmentUpdated = 'scheduler:appointments:updated';
    public static SchedulerAppointmentDeleted = 'scheduler:appointments:deleted';
    public static SchedulerAppointmentDragged = 'scheduler:appointments:dragged';
    public static SchedulerAppointmentResized = 'scheduler:appointments:resized';
    public static SchedulerAppointmentRefresh = 'scheduler:appointments:refresh';
    public static SchedulerAppointmentPasted = 'scheduler:appointments:pasted';
    public static SchedulerDateChanged = 'scheduler:dates:changed';
    public static SchedulerUsersChanged = 'scheduler:users:changed';
    public static SchedulerLocationsChanged = 'scheduler:locations:changed';
    public static ScheduleOverlappingTimeslot = 'schedule:timeslots:overlapping';
    public static ScheduleSettingsChanged = 'schedule:settings:changed';
    public static TasksListsArchived = 'tasks:lists:archived';
    public static TasksListsDeleted = 'tasks:lists:deleted';
    public static TasksListsMoved = 'tasks:lists:moved';
    public static TasksMoved = 'tasks:moved';
    public static UserPostsCreated = 'user-posts:created';
    public static UserPostsDeleted = 'user-posts:deleted';
    public static UserPostsReplied = 'user-posts:replied';
    public static DeclarationsUpdated = 'declarations:updated';
    public static DeclarationPerformancesUpdated = 'declarations:performances:updated';
    public static DeclarationPerformancesCredited = 'declarations:performances:credited';
    public static HealthcareInvoicesUpdated = 'healthcare-invoices:updated';
}
