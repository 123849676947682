import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    DayOfWeek,
    GetPracticeLocationResponse,
    PracticeLocationEntityReference,
    PracticeLocationsApiClient,
    ScheduleRegistration,
    ScheduleRoomRegistration,
    ScheduleTimeSlot,
    ScheduleTimeSlotParticulars,
    SchedulesApiClient,
    UpdateScheduleRequest
} from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { isDefined, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { areIntervalsOverlapping, intervalToDuration, isAfter, isEqual, secondsToMinutes, setHours, setMinutes } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cloneDeep, dayAndTimeToDate, formatForTimeOnly, getTotalAmountOfSlots, validateTime } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, SchedulesApiClient, ModalService, PracticeLocationsApiClient)
export class PartialManageSchedulesEdit extends BasePartialView {
    public schedule: UpdateScheduleRequest;
    public DayOfWeek: typeof DayOfWeek = DayOfWeek;
    public validation = {
        timeSlots: true,
        any: true,
        location: true,
        registrations: [],
        validRegistrations: true
    };
    public location: GetPracticeLocationResponse;
    public ScheduleTimeSlotParticulars: typeof ScheduleTimeSlotParticulars = ScheduleTimeSlotParticulars;
    public roomSelector: UxSelect;
    public errorCode: string;
    public active: ScheduleRegistration;
    public activeIndex: number;
    public settings: SchedulerSettings;
    public hours: { value: number; label: string }[] = [];
    public scheduleState: {
        [key: string]: {
            rooms: {
                [key: string]: {
                    loading: boolean;
                    layers: { [key: string]: { zIndex: number } };
                };
            };
        };
    } = {};

    private scheduleId: string;
    private day: DayOfWeek;
    private days: string[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly schedulesApi: SchedulesApiClient,
        private readonly modalService: ModalService,
        private readonly locationsApi: PracticeLocationsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.scheduleId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.settings = this.state.schedulerSettings;
                this.schedule = await this.schedulesApi.getById(this.scheduleId, this.authenticated.workspace.id);
                this.location = await this.locationsApi.getById(this.schedule.location.id, this.authenticated.workspace.id);

                // Render periods based on the start and end hour from the scheduler settings.
                for (let hour = this.settings.start.hour; hour <= this.settings.end.hour; hour++) {
                    // Only add the last hour if the end minute is not 0.
                    if (hour === this.settings.end.hour && this.settings.end.minute === 0) continue;
                    this.hours.push({ value: hour, label: `${hour.toString().padStart(2, '0')}:00` });
                }

                for (let rI = 0; rI < this.schedule.registrations.length; rI++) {
                    const registration = this.schedule.registrations[rI];
                    this.scheduleState[rI] = { rooms: {} };
                    this.validation.registrations.push({
                        rooms: [],
                        valid: true
                    });

                    for (let rrI = 0; rrI < registration.roomRegistrations.length; rrI++) {
                        const roomRegistration = registration.roomRegistrations[rrI];
                        this.scheduleState[rI].rooms[rrI] = { loading: false, layers: {} };
                        this.validation.registrations[rI].rooms.push({
                            valid: true,
                            slots: []
                        });

                        for (let sI = 0; sI < roomRegistration.slots.length; sI++) {
                            this.validation.registrations[rI].rooms[rrI].slots.push({
                                start: true,
                                end: true,
                                range: true,
                                overlap: true,
                                practitioner: true,
                                valid: true
                            });
                        }
                    }
                }

                this.days = Array.from(Reflect.ownKeys(DayOfWeek)) as string[];
                // Start on monday.
                this.day = DayOfWeek[this.days[1]];

                // Set the registartion of the second day (monday) as active.
                this.active = this.schedule.registrations[1];
                this.activeIndex = 1;

                // Set initial width of view.
                this.setWidth(440 + this.location.rooms.filter((x) => x.isTreatmentRoom).length * 297);
                // Set the minimum width of the view when resizing view width.
                this.constrainViewWidth = (width: number): number => {
                    const minWidth = 440 + this.location.rooms.filter((x) => x.isTreatmentRoom).length * 282;
                    return width < minWidth ? minWidth : width;
                };

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(
                        CustomEvents.ScheduleOverlappingTimeslot,
                        (data: {
                            mode: 'mark' | 'reset';
                            index: number;
                            room: number;
                            day: DayOfWeek; //
                        }) => {
                            if (data.mode === 'mark') {
                                const registrationIndex = this.schedule.registrations.findIndex((x) => x.day == data.day);
                                this.validation.validRegistrations = false;
                                this.validation.registrations[registrationIndex].rooms[data.room].slots[data.index].valid = false;
                                this.validation.registrations[registrationIndex].rooms[data.room].valid = false;
                                this.validation.registrations[registrationIndex].valid = false;
                            } else {
                                // Reset the validation
                                this.validation.validRegistrations = true;
                                for (let rI = 0; rI < this.schedule.registrations.length; rI++) {
                                    this.validation.registrations[rI].valid = true;
                                    const registration = this.schedule.registrations[rI];
                                    for (let rrI = 0; rrI < registration.roomRegistrations.length; rrI++) {
                                        this.validation.registrations[rI].rooms[rrI].valid = true;
                                        const roomRegistration = registration.roomRegistrations[rrI];
                                        for (let sI = 0; sI < roomRegistration.slots.length; sI++) {
                                            resetValidation(this.validation.registrations[rI].rooms[rrI].slots[sI]);
                                        }
                                    }
                                }
                            }
                        }
                    )
                ];

                this.render(this.activeIndex);
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageSchedulesEdit.attached', x));
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());

        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Canceled,
            updateUrl: true
        });
    }

    public async refreshLocation(): Promise<void> {
        this.location = await this.locationsApi.getById(this.schedule.location.id, this.authenticated.workspace.id);
        this.schedule.location = new PracticeLocationEntityReference({
            id: this.location.id,
            translations: this.location.name,
            data: {
                nl: this.location.applicationName['nl'],
                en: this.location.applicationName['en']
            }
        });

        // Update all room names as well.
        for (const registration of this.schedule.registrations) {
            for (const roomRegistration of registration.roomRegistrations) {
                roomRegistration.room = this.location.rooms.find((x) => x.id === roomRegistration.room.id);
            }
        }

        this.save();
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.errorCode = null;
            this.isLoading = true;
            try {
                // Format the times correctly
                for (const slot of this.schedule.registrations.selectMany(
                    (r: ScheduleRegistration) => r.roomRegistrations.selectMany((rr: ScheduleRoomRegistration) => rr.slots) //
                )) {
                    slot.start = formatForTimeOnly(slot.start);
                    slot.end = formatForTimeOnly(slot.end);
                }
                await this.schedulesApi.update(this.scheduleId, this.authenticated.workspace.id, this.schedule);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-schedules.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-schedules.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.schedule.location.translations[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                try {
                    const error = JSON.parse(e.response);
                    if (error.code === 212) {
                        this.errorCode = error.message.substring(error.message.indexOf('(') + 1, error.message.lastIndexOf(')'));
                    } else await this.errorHandler.handle('[edit-schedule]', e);
                } catch (err) {
                    await this.errorHandler.handle('[edit-schedule]', { e, err });
                }
            }
        }
    }

    public async nextDay(): Promise<void> {
        await this.removeChildViews();
        let index = this.days.indexOf(this.day.toString());
        if (index == 6) index = 0;
        else index += 1;
        this.day = DayOfWeek[this.days[index]];

        this.render(index);
        this.activeIndex = index;
        this.active = this.schedule.registrations[this.activeIndex];
    }

    public async previousDay(): Promise<void> {
        await this.removeChildViews();
        let index = this.days.indexOf(this.day.toString());
        if (index == 0) index = 6;
        else index -= 1;
        this.day = DayOfWeek[this.days[index]];

        this.render(index);
        this.activeIndex = index;
        this.active = this.schedule.registrations[this.activeIndex];
    }

    public handleLocationSelected = (location: GetPracticeLocationResponse): void => {
        this.schedule.location = new PracticeLocationEntityReference({
            id: location.id,
            translations: location.name,
            data: {
                nl: location.applicationName['nl'],
                en: location.applicationName['en']
            }
        });
        this.location = location;

        // Override all the room registrations.
        for (let rI = 0; rI < this.schedule.registrations.length; rI++) {
            resetValidation(this.validation.registrations[rI]);
            const registration = this.schedule.registrations[rI];
            registration.roomRegistrations = this.location.rooms
                .filter((x) => x.isTreatmentRoom)
                .map(
                    (room) =>
                        new ScheduleRoomRegistration({
                            room,
                            slots: []
                        })
                );
            this.validation.registrations[rI].rooms = this.location.rooms.filter((x) => x.isTreatmentRoom).map(() => ({ valid: true, slots: [] }));
        }

        this.setWidth(400 + this.location.rooms.filter((x) => x.isTreatmentRoom).length * 297);
        this.scrollTo(this.partial);
    };

    public removeTimeSlot(parent: number, index: number): void {
        this.scheduleState[this.activeIndex].rooms[parent].loading = true;

        this.schedule.registrations[this.activeIndex].roomRegistrations[parent].slots.splice(index, 1);
        this.validation.registrations[this.activeIndex].rooms[parent].slots.splice(index, 1);

        this.render(this.activeIndex);
        this.scheduleState[this.activeIndex].rooms[parent].loading = false;
    }

    public async createOrEditSlot(parent: number = -1, index: number = -1): Promise<void> {
        const slot = index > -1 ? this.schedule.registrations[this.activeIndex].roomRegistrations[parent].slots[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.ScheduleTimeslot.with({
                slot: cloneDeep(slot),
                parent,
                index,
                room: this.schedule.registrations[this.activeIndex].roomRegistrations[parent].room.name[this.language],
                otherSlots: this.schedule.registrations[this.activeIndex].roomRegistrations[parent].slots,
                day: this.schedule.registrations[this.activeIndex].day
            }).whenClosed(async (result: PartialViewResults, data: { slot: ScheduleTimeSlot; parent: number; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.scheduleState[this.activeIndex].rooms[data.parent].loading = true;

                    if (data.index > -1) this.schedule.registrations[this.activeIndex].roomRegistrations[data.parent].slots[data.index] = data.slot;
                    else {
                        this.schedule.registrations[this.activeIndex].roomRegistrations[data.parent].slots.push(data.slot);
                        this.validation.registrations[this.activeIndex].rooms[data.parent].slots.push({
                            start: true,
                            end: true,
                            range: true,
                            overlap: true,
                            practitioner: true,
                            valid: true
                        });
                    }

                    this.render(this.activeIndex);
                    this.scheduleState[this.activeIndex].rooms[data.parent].loading = false;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public generateStyling(
        slot: ScheduleTimeSlot,
        room: number
    ): {
        height: string; //
        top: string;
        width: string;
        zIndex: number;
        backgroundColor: string;
    } {
        const day = this.schedule.registrations[this.activeIndex].day;
        const start = dayAndTimeToDate(day, slot.start);
        const end = dayAndTimeToDate(day, slot.end);
        const duration = intervalToDuration({ start, end });
        const durationInSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;

        // First calculate the total amount of slots and the percentage per slot.
        const totalAmountOfSlots = getTotalAmountOfSlots(start, this.settings);
        const percentagePerSlot = 100 / totalAmountOfSlots;

        const getStartingPosition = (start: Date): number => {
            // First get the duration from the start of the day to the start of the appointment.
            const durationBeforeStart = intervalToDuration({
                start: setMinutes(setHours(start, this.settings.start.hour), this.settings.start.minute),
                end: start
            });
            // Calculate how many slots we need for the duration before the start of the appointment.
            const slotsForTheHours = durationBeforeStart.hours * (60 / this.settings.slotSize);
            const slotsForTheMinutes = durationBeforeStart.minutes / this.settings.slotSize;
            // Calculate the percentage for the duration before the start of the appointment.
            return (slotsForTheHours + slotsForTheMinutes) * percentagePerSlot;
        };

        const getHeight = (): number => {
            let durationInMinutes = secondsToMinutes(durationInSeconds);

            // First calculate how many blocks the appointment takes by first parsing the duration (which is in seconds) to minutes.
            // Then devide the duration in minuts it by the minutes per block.
            let height =
                (durationInMinutes / this.settings.slotSize) *
                // Then multiply the amount of blocks by the percentage to get the height for the appointment in percentage.
                percentagePerSlot;

            // We need to check if the height doesn't exceed the total height of the column.
            // If it does, we need to set the height to the maximum height.
            if (startPosition + height > 100) {
                // We calculate the height by subtracting the start percentage from the total height of the column.
                height = 100 - startPosition;
                // Mark the appointment as 'continuing on the next day'.
            }

            return height;
        };

        const startPosition = getStartingPosition(start);
        const height = getHeight();

        const layer = this.scheduleState[this.activeIndex]?.rooms[room]?.layers[slot.id];
        const zIndex = layer?.zIndex ?? 0;

        // Reduce the width of the appointment based on the the z-index.
        // So that overlapping appointments stays visible.
        const widthBlockPercentage = 10;
        const width = 100 - widthBlockPercentage * zIndex;

        return {
            height: `${height}%`, //
            top: `${startPosition}%`,
            width: `${width}%`,
            zIndex: zIndex,
            backgroundColor: zIndex > 0 ? '#fef9c3' : '#e0f2fe'
        };
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-schedules.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-schedules.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.schedule.location.translations[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.schedulesApi.delete(this.scheduleId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-schedules.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-schedules.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.schedule.location.translations[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-schedule]', e);
                        }
                    }
                }
            })
        );
    }

    private render(activeIndex: number): void {
        for (let roomIndex = 0; roomIndex < this.schedule.registrations[activeIndex].roomRegistrations.length; roomIndex++) {
            const roomRegistration = this.schedule.registrations[activeIndex].roomRegistrations[roomIndex];
            const slots = roomRegistration.slots;

            // Sort first by start date and then by duration.
            slots.sort(this.sortByStartDateAndDuration);

            // Lets loop through each appointment and find the ones that overlap.
            for (let index = 0; index < slots.length; index++) {
                const slot = slots[index];
                // Reset layer
                this.scheduleState[activeIndex].rooms[roomIndex].layers[slot.id] = { zIndex: 0 };
                // Check if this appointment overlaps with any other appointments.
                const overlaps = slots.filter((a) => this.checkIfOverlaps(slot, a));
                // Check if the overlaps come before the current appointment.
                // Note that the appointments are already sorted by start date.
                // If the overlap comes before the current appointment, we need to
                // increase the z-index.
                const overlapsThatComeBeforeCurrentApointment = overlaps.filter((overlap) => {
                    const overlapIndex = slots.findIndex((a) => a.id === overlap.id);
                    return overlapIndex < index;
                });

                const zIndex = overlapsThatComeBeforeCurrentApointment.any()
                    ? // If we have overlaps use the biggest z-index + 1.
                      Math.max(...overlapsThatComeBeforeCurrentApointment.map((x) => this.scheduleState[activeIndex].rooms[roomIndex].layers[x.id]?.zIndex)) + 1
                    : // Otherwise use 0.
                      0;

                this.scheduleState[activeIndex].rooms[roomIndex].layers[slot.id] = { zIndex };
            }

            this.schedule.registrations[activeIndex].roomRegistrations[roomIndex].slots = slots;
        }
    }

    private checkIfOverlaps(slot1: ScheduleTimeSlot, slot2: ScheduleTimeSlot): boolean {
        // Don't compare with self.
        if (slot1.id === slot2.id) return false;

        const day = this.schedule.registrations[this.activeIndex].day;

        const start1 = dayAndTimeToDate(day, slot1.start);
        const end1 = dayAndTimeToDate(day, slot1.end);
        const start2 = dayAndTimeToDate(day, slot2.start);
        const end2 = dayAndTimeToDate(day, slot2.end);

        return areIntervalsOverlapping(
            { start: start1, end: end1 }, //
            { start: start2, end: end2 }
        );
    }

    private sortByStartDateAndDuration = (a: ScheduleTimeSlot, b: ScheduleTimeSlot): number => {
        const day = this.schedule.registrations[this.activeIndex].day;

        let startA = dayAndTimeToDate(day, a.start);
        let startB = dayAndTimeToDate(day, b.start);

        if (startA.getTime() > startB.getTime()) return 1;
        else if (startA.getTime() < startB.getTime()) return -1;

        // Sort on duration
        return b.duration - a.duration;
    };

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.location = isDefined(this.schedule.location);

        this.validation.any = this.schedule.registrations
            .selectMany(
                (r: ScheduleRegistration) => r.roomRegistrations.selectMany((rr: ScheduleRoomRegistration) => rr.slots) //
            )
            .any();

        for (let rI = 0; rI < this.schedule.registrations.length; rI++) {
            const registration = this.schedule.registrations[rI];

            for (let rrI = 0; rrI < registration.roomRegistrations.length; rrI++) {
                const roomRegistration = registration.roomRegistrations[rrI];

                for (let sI = 0; sI < roomRegistration.slots.length; sI++) {
                    const slot = roomRegistration.slots[sI];
                    resetValidation(this.validation.registrations[rI].rooms[rrI].slots[sI]);

                    const start = dayAndTimeToDate(registration.day, slot.start);
                    const end = dayAndTimeToDate(registration.day, slot.end);

                    this.validation.registrations[rI].rooms[rrI].slots[sI].start = validateTime(slot.start);
                    this.validation.registrations[rI].rooms[rrI].slots[sI].end = validateTime(slot.end);
                    this.validation.registrations[rI].rooms[rrI].slots[sI].practitioner = isDefined(slot.practitioner);
                    this.validation.registrations[rI].rooms[rrI].slots[sI].range = !isEqual(start, end) && isAfter(end, start);

                    if (this.validation.registrations[rI].rooms[rrI].slots[sI].range) {
                        // Check if overlaps and return the index of the period that overlaps
                        const overlaps = this.schedule.registrations[rI].roomRegistrations[rrI].slots.findIndex((slotToCompare: ScheduleTimeSlot) => {
                            // Don't compare it to itself.
                            if (slotToCompare.id === slot.id) return false;

                            // First check if they overlap based on their timestamps.
                            const doTheyOverlapOnTime = areIntervalsOverlapping(
                                { start, end }, //
                                { start: dayAndTimeToDate(this.day, slotToCompare.start), end: dayAndTimeToDate(this.day, slotToCompare.end) }
                            );

                            // Of course the same practitioner can't have overlapping periods.
                            // When reaching this check we assume both slots have a practitioner selected.
                            const matchingPractitioners = slotToCompare.practitioner.id === slot.practitioner.id;

                            const slot1HasParticulars = isDefined(slot.particulars);
                            const slot2HasParticulars = isDefined(slotToCompare.particulars);

                            // Now we have to check if they have a valid or invalid particular combination.
                            // There are three valid combinations, namely:
                            // 1: Both have no particulars.
                            // 2: One of the slots has EvenWeek and the other has OddWeek (or visa versa).
                            // 3: One of the slots has LastOfMonth and the other has FirstOfMonth (or visa versa).
                            // All other combinations are invalid.
                            let hasInvalidParticulars = false;
                            if ((slot1HasParticulars && !slot2HasParticulars) || (!slot1HasParticulars && slot2HasParticulars)) {
                                // One slot has particulars, but the other does not. This is invalid.
                                hasInvalidParticulars = true;
                            } else if (slot1HasParticulars && slot2HasParticulars) {
                                // Both slots have particulars, so we need to check if the combination is valid.
                                // First define what combinations are valid combinations.
                                const validCombinations = [
                                    [ScheduleTimeSlotParticulars.EvenWeek, ScheduleTimeSlotParticulars.OddWeek],
                                    [ScheduleTimeSlotParticulars.OddWeek, ScheduleTimeSlotParticulars.EvenWeek],
                                    [ScheduleTimeSlotParticulars.LastOfMonth, ScheduleTimeSlotParticulars.FirstOfMonth],
                                    [ScheduleTimeSlotParticulars.FirstOfMonth, ScheduleTimeSlotParticulars.LastOfMonth]
                                ];

                                // Now check if the current combination matches one of the valid combinations.
                                const slot1Particular = slot.particulars;
                                const slot2Particular = slotToCompare.particulars;

                                // If the current combination matches one of the valid combinations, then
                                // the 'some' check will return true. So we have to reverse the result,
                                // meaning this combination does NOT have invalid particulars.
                                hasInvalidParticulars = !validCombinations.some(([firstOfValidCombo, secondOfValidCombo]) => {
                                    // We don't have to reverse (visa versa) the combination because we added both
                                    // options to the valid combinations array.
                                    return firstOfValidCombo === slot1Particular && secondOfValidCombo === slot2Particular;
                                });
                            } else {
                                // Both slots have no particulars. This is valid.
                                // Mark it as true so it will always pass the check in the return value.
                                // Meaning in this case we ignore the 'hasInvalidParticulars' flag.
                                hasInvalidParticulars = true;
                            }

                            return doTheyOverlapOnTime
                                ? // Return true if they have invalid particulars OR if they have the same practitioner.
                                  hasInvalidParticulars || matchingPractitioners
                                : // If they do not overlap on time. Don't bother checking the rest.
                                  false;
                        });

                        // Mark the period as overlapping
                        this.validation.registrations[rI].rooms[rrI].slots[sI].overlap = overlaps === -1;
                        // Mark the other period as overlapping
                        if (overlaps !== -1) this.validation.registrations[rI].rooms[rrI].slots[overlaps].overlap = false;
                    }

                    this.validation.registrations[rI].rooms[rrI].slots[sI].valid =
                        this.validation.registrations[rI].rooms[rrI].slots[sI].start &&
                        this.validation.registrations[rI].rooms[rrI].slots[sI].end &&
                        this.validation.registrations[rI].rooms[rrI].slots[sI].range &&
                        this.validation.registrations[rI].rooms[rrI].slots[sI].overlap &&
                        this.validation.registrations[rI].rooms[rrI].slots[sI].practitioner;
                }

                this.validation.registrations[rI].rooms[rrI].valid = this.validation.registrations[rI].rooms[rrI].slots.every(validateState);
            }

            this.validation.registrations[rI].valid = this.validation.registrations[rI].rooms.every(validateState);
        }

        this.validation.validRegistrations = this.validation.registrations.every(validateState);

        return validateState(this.validation);
    }
}
