<import from="bx/bx-scheduler-header/bx-scheduler-header" />
<import from="bx/bx-scheduler-header-column/bx-scheduler-header-column" />
<import from="bx/bx-scheduler-column/bx-scheduler-column" />
<import from="bx/bx-scheduler-timeline/bx-scheduler-timeline" />
<import from="bx/bx-scheduler-tasks/bx-scheduler-tasks" />
<import from="bx/bx-scheduler-tasks-column/bx-scheduler-tasks-column" />

<template class="flex h-full flex-1 flex-col overflow-hidden">
    <div if.bind="baseLoaded" class="flex flex-1 flex-col overflow-hidden">
        <div class="${schedulerState.isSaving ? '' : 'pt-[5px]'}">
            <ux-bar-loader if.bind="schedulerState.isSaving"></ux-bar-loader>
            <bx-scheduler-header
                on-new-appointment.bind="handleNewAppointment"
                has-role.bind="hasRole"
                user-selection.bind="users"
                wrapper-selection.bind="wrappers"
                show-today.bind="showToday"
                workspace.bind="workspace"
                view-width.bind="viewWidth"
                authenticated-user.bind="authenticatedUser"
                start-date.bind="startDate"
                language.bind="language">
            </bx-scheduler-header>
        </div>
        <div class="flex w-full overflow-x-auto overflow-y-hidden">
            <div ref="scrollContainer" scroll.trigger="handleScroll($event)" class="flex w-full flex-1 flex-col overflow-y-scroll">
                <!-- 
                    Min width should be the amount of columns times 'settings.minColumnWidth' pixels. Only one of the sitations is true at a time  
                    so we can add up all calculations. When no users and no wrappers 7 days are present.
                    If the amount of days are configurable in the future, replace 7 with 'settings.days.length'.
                    Also add 60 for the fixed width of the timeline.
                -->
                <div
                    css="min-width: ${users.length * settings.minColumnWidth + wrappers.length * settings.minColumnWidth + (users.length === 0 && wrappers.length === 0 ? 7 * settings.minColumnWidth : 0) + 60}px;"
                    class="sticky top-0 z-40 flex w-full flex-col bg-white">
                    <div class="flex rounded-t-lg border-b border-gray-200">
                        <div class="sticky left-0 z-10 w-[60px] min-w-[60px] border-gray-200 bg-transparent"></div>
                        <template if.bind="users.length > 0">
                            <template if.bind="users.length === 1">
                                <bx-scheduler-header-column
                                    repeat.for="day of settings.days"
                                    component.ref="headers[$index]"
                                    headers.bind="headers"
                                    force-dates.bind="true"
                                    date.bind="week[day]"
                                    user.bind="users[0]"
                                    settings.bind="settings"
                                    language.bind="language">
                                </bx-scheduler-header-column>
                            </template>
                            <template else>
                                <bx-scheduler-header-column
                                    repeat.for="user of users"
                                    component.ref="headers[$index]"
                                    headers.bind="headers"
                                    user.bind="user"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    language.bind="language">
                                </bx-scheduler-header-column>
                            </template>
                        </template>
                        <template if.bind="wrappers.length > 0">
                            <template if.bind="wrappers.length === 1">
                                <bx-scheduler-header-column
                                    repeat.for="day of settings.days"
                                    component.ref="headers[$index]"
                                    force-dates.bind="true"
                                    headers.bind="headers"
                                    date.bind="week[day]"
                                    wrapper.bind="wrappers[0]"
                                    settings.bind="settings"
                                    language.bind="language">
                                </bx-scheduler-header-column>
                            </template>
                            <template else>
                                <bx-scheduler-header-column
                                    repeat.for="wrapper of wrappers"
                                    component.ref="headers[$index]"
                                    headers.bind="headers"
                                    wrapper.bind="wrapper"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    language.bind="language">
                                </bx-scheduler-header-column>
                            </template>
                        </template>
                        <template if.bind="users.length === 0 && wrappers.length === 0">
                            <bx-scheduler-header-column
                                repeat.for="day of settings.days"
                                component.ref="headers[$index]"
                                headers.bind="headers"
                                date.bind="week[day]"
                                settings.bind="settings"
                                language.bind="language">
                            </bx-scheduler-header-column>
                        </template>
                    </div>
                    <bx-scheduler-tasks if.bind="hasRole(UserRoles.ReadHealthcareTasks)" has-scrolled.bind="scrollPositionX > 0" page-state.two-way="pageState">
                        <template if.bind="users.length > 0">
                            <template if.bind="users.length === 1">
                                <bx-scheduler-tasks-column
                                    repeat.for="day of settings.days"
                                    date.bind="week[day]"
                                    settings.bind="settings"
                                    on-task-click.bind="onTaskClick"
                                    authenticated-user.bind="authenticatedUser"
                                    language.bind="language"
                                    workspace.bind="workspace">
                                </bx-scheduler-tasks-column>
                            </template>
                            <template else>
                                <bx-scheduler-tasks-column
                                    repeat.for="user of users"
                                    user.bind="user"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    on-task-click.bind="onTaskClick"
                                    authenticated-user.bind="authenticatedUser"
                                    language.bind="language"
                                    workspace.bind="workspace">
                                </bx-scheduler-tasks-column>
                            </template>
                        </template>
                        <template if.bind="wrappers.length > 0">
                            <template if.bind="wrappers.length === 1">
                                <bx-scheduler-tasks-column
                                    repeat.for="day of settings.days"
                                    date.bind="week[day]"
                                    settings.bind="settings"
                                    on-task-click.bind="onTaskClick"
                                    authenticated-user.bind="authenticatedUser"
                                    language.bind="language"
                                    workspace.bind="workspace">
                                </bx-scheduler-tasks-column>
                            </template>
                            <template else>
                                <bx-scheduler-tasks-column
                                    repeat.for="wrapper of wrappers"
                                    wrapper.bind="wrapper"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    on-task-click.bind="onTaskClick"
                                    authenticated-user.bind="authenticatedUser"
                                    language.bind="language"
                                    workspace.bind="workspace">
                                </bx-scheduler-tasks-column>
                            </template>
                        </template>
                        <template if.bind="users.length === 0 && wrappers.length === 0">
                            <bx-scheduler-tasks-column
                                repeat.for="day of settings.days"
                                date.bind="week[day]"
                                settings.bind="settings"
                                on-task-click.bind="onTaskClick"
                                authenticated-user.bind="authenticatedUser"
                                language.bind="language"
                                workspace.bind="workspace">
                            </bx-scheduler-tasks-column>
                        </template>
                    </bx-scheduler-tasks>
                </div>
                <div class="relative flex w-full">
                    <div border-r.class="scrollPositionX > 0" class="sticky left-0 z-20 h-auto border-gray-200 bg-white">
                        <bx-scheduler-timeline periods.bind="periods" settings.bind="settings"></bx-scheduler-timeline>
                    </div>
                    <template if.bind="users.length > 0">
                        <template if.bind="users.length === 1">
                            <bx-scheduler-column
                                repeat.for="day of settings.days"
                                has-role.bind="hasRole"
                                workspace.bind="workspace"
                                language.bind="language"
                                index.bind="$index"
                                user.bind="users[0]"
                                component.ref="columns[$index]"
                                columns.bind="columns"
                                periods.bind="periods"
                                state.bind="schedulerState"
                                date.bind="week[day]"
                                settings.bind="settings"
                                has-role.bind="hasRole"
                                on-appointment-click.bind="handleAppointmentClick"
                                on-appointment-create.bind="handleAppointmentCreate"
                                on-appointment-edit.bind="handleAppointmentEdit"
                                on-appointment-delete.bind="handleAppointmentDelete"
                                on-appointment-move.bind="handleAppointmentMove"
                                on-appointment-resize.bind="handleAppointmentResize"
                                on-patient-card.bind="handlePatientCard"
                                on-confirm-appointment.bind="handleConfirmAppointment"
                                on-mark-no-show.bind="handleMarkNoShow"
                                on-unmark-no-show.bind="handleUnmarkNoShow">
                            </bx-scheduler-column>
                        </template>
                        <template else>
                            <bx-scheduler-column
                                repeat.for="user of users"
                                has-user-filter.bind="true"
                                workspace.bind="workspace"
                                language.bind="language"
                                index.bind="$index"
                                user.bind="user"
                                component.ref="columns[$index]"
                                columns.bind="columns"
                                periods.bind="periods"
                                state.bind="schedulerState"
                                date.bind="startDate"
                                settings.bind="settings"
                                has-role.bind="hasRole"
                                on-appointment-click.bind="handleAppointmentClick"
                                on-appointment-create.bind="handleAppointmentCreate"
                                on-appointment-edit.bind="handleAppointmentEdit"
                                on-appointment-delete.bind="handleAppointmentDelete"
                                on-appointment-move.bind="handleAppointmentMove"
                                on-appointment-resize.bind="handleAppointmentResize"
                                on-patient-card.bind="handlePatientCard"
                                on-confirm-appointment.bind="handleConfirmAppointment"
                                on-mark-no-show.bind="handleMarkNoShow"
                                on-unmark-no-show.bind="handleUnmarkNoShow">
                            </bx-scheduler-column>
                        </template>
                    </template>
                    <template if.bind="wrappers.length > 0">
                        <template if.bind="wrappers.length === 1">
                            <bx-scheduler-column
                                repeat.for="day of settings.days"
                                workspace.bind="workspace"
                                language.bind="language"
                                index.bind="$index"
                                wrapper.bind="wrappers[0]"
                                component.ref="columns[$index]"
                                columns.bind="columns"
                                periods.bind="periods"
                                state.bind="schedulerState"
                                date.bind="week[day]"
                                settings.bind="settings"
                                has-role.bind="hasRole"
                                on-appointment-click.bind="handleAppointmentClick"
                                on-appointment-create.bind="handleAppointmentCreate"
                                on-appointment-edit.bind="handleAppointmentEdit"
                                on-appointment-delete.bind="handleAppointmentDelete"
                                on-appointment-move.bind="handleAppointmentMove"
                                on-appointment-resize.bind="handleAppointmentResize"
                                on-patient-card.bind="handlePatientCard"
                                on-confirm-appointment.bind="handleConfirmAppointment"
                                on-mark-no-show.bind="handleMarkNoShow"
                                on-unmark-no-show.bind="handleUnmarkNoShow">
                            </bx-scheduler-column>
                        </template>
                        <template else>
                            <bx-scheduler-column
                                repeat.for="wrapper of wrappers"
                                has-user-filter.bind="true"
                                workspace.bind="workspace"
                                language.bind="language"
                                index.bind="$index"
                                wrapper.bind="wrapper"
                                component.ref="columns[$index]"
                                columns.bind="columns"
                                periods.bind="periods"
                                state.bind="schedulerState"
                                date.bind="startDate"
                                settings.bind="settings"
                                has-role.bind="hasRole"
                                on-appointment-click.bind="handleAppointmentClick"
                                on-appointment-create.bind="handleAppointmentCreate"
                                on-appointment-edit.bind="handleAppointmentEdit"
                                on-appointment-delete.bind="handleAppointmentDelete"
                                on-appointment-move.bind="handleAppointmentMove"
                                on-appointment-resize.bind="handleAppointmentResize"
                                on-patient-card.bind="handlePatientCard"
                                on-confirm-appointment.bind="handleConfirmAppointment"
                                on-mark-no-show.bind="handleMarkNoShow"
                                on-unmark-no-show.bind="handleUnmarkNoShow">
                            </bx-scheduler-column>
                        </template>
                    </template>
                    <template if.bind="users.length === 0 && wrappers.length === 0">
                        <bx-scheduler-column
                            repeat.for="day of settings.days"
                            workspace.bind="workspace"
                            language.bind="language"
                            index.bind="$index"
                            user.bind="authenticatedUser"
                            component.ref="columns[$index]"
                            columns.bind="columns"
                            periods.bind="periods"
                            state.bind="schedulerState"
                            date.bind="week[day]"
                            settings.bind="settings"
                            has-role.bind="hasRole"
                            on-appointment-click.bind="handleAppointmentClick"
                            on-appointment-create.bind="handleAppointmentCreate"
                            on-appointment-edit.bind="handleAppointmentEdit"
                            on-appointment-delete.bind="handleAppointmentDelete"
                            on-appointment-move.bind="handleAppointmentMove"
                            on-appointment-resize.bind="handleAppointmentResize"
                            on-patient-card.bind="handlePatientCard"
                            on-confirm-appointment.bind="handleConfirmAppointment"
                            on-mark-no-show.bind="handleMarkNoShow"
                            on-unmark-no-show.bind="handleUnmarkNoShow">
                        </bx-scheduler-column>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
