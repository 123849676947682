import { GetUserResponse } from '@wecore/sdk-core';
import { SchedulerSettings } from '@wecore/sdk-management';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { format } from 'date-fns';
import { CustomEvents } from '../../infra/events';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';

@containerless
@inject(IEventAggregator)
export class BxSchedulerHeaderColumn {
    @bindable() public settings: SchedulerSettings;
    @bindable() public date: Date;
    @bindable() public user: GetUserResponse;
    @bindable() public wrapper: SchedulerRoomWrapper;
    @bindable() public headers: BxSchedulerHeaderColumn[];
    @bindable() public language: string;
    @bindable() public forceDates: boolean = false;

    public isToday: boolean = false;
    private subscriptions: IDisposable[] = [];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public bound(): void {
        this.checkForToday();
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerDateChanged, () => this.checkForToday()) //
        ];
    }

    public refresh(): void {
        this.checkForToday();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }

    private checkForToday(): void {
        this.isToday = format(this.date, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd');
    }
}
