import { SchedulerSettings } from '@wecore/sdk-management';
import { bindable, containerless } from 'aurelia';
import { getSchedulerPeriodHeight } from '../../infra/utilities';
import { SchedulerPeriod } from '../../models/scheduler-period';

@containerless
export class BxSchedulerPeriod {
    @bindable() public settings: SchedulerSettings;
    @bindable() public period: SchedulerPeriod;

    public container: HTMLDivElement;
    public height: number;

    public bound(): void {
        this.height = getSchedulerPeriodHeight(this.settings, this.period, this.settings.periodHeight);
        this.container.style.height = `${this.height}px`;
    }
}
