<import from="converters/translate" />
<import from="converters/date" />
<import from="bx/bx-appointment-type-selector/bx-appointment-type-selector" />
<import from="bx/bx-practice-location-selector/bx-practice-location-selector" />
<import from="bx/bx-examination-room-selector/bx-examination-room-selector" />
<import from="bx/bx-patient-selector/bx-patient-selector" />
<import from="bx/bx-user-selector/bx-user-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 pt-5 pb-3 bg-white border-b border-gray-200">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.appointments.edit.title"></p>
                        </div>
                        <div
                            class="flex flex-col h-32 px-5 pt-5 pb-3 rounded-xl"
                            css="background-color: ${appointment.type?.color.background ?? '#e5e7eb'}; color: ${appointment.type?.color.text ?? '#1f2937'}">
                            <div class="flex flex-col flex-1">
                                <div class="flex gap-1 text-sm font-semibold">
                                    <span>#${appointment.patient?.data.number ?? '12345'}</span>
                                    <span>${appointment.patient?.name ?? 'John Doe'}</span>
                                </div>
                                <div class="flex gap-1 text-xs font-semibold">
                                    <span>${appointment.start | date:'HH:mm' } - ${appointment.end | date:'HH:mm' }</span>
                                </div>
                                <div if.bind="appointment.type" class="flex gap-1 text-xs">
                                    <span>${appointment.type.name[language]} </span>
                                </div>
                            </div>
                        </div>
                        <template if.bind="errorCode">
                            <ux-alert color="danger">
                                <div class="text-sm">
                                    <span if.bind="errorCode === 'AP401'" t="translation:partial-views.appointments.messages.error-AP401"></span>
                                    <span if.bind="errorCode === 'AP402'" t="translation:partial-views.appointments.messages.error-AP402"></span>
                                    <span if.bind="errorCode === 'AP403'" t="translation:partial-views.appointments.messages.error-AP403"></span>
                                    <span if.bind="errorCode === 'AP404'" t="translation:partial-views.appointments.messages.error-AP404"></span>
                                </div>
                            </ux-alert>
                        </template>
                    </div>
                    <div class="grid grid-cols-12 gap-2 pt-3 pb-5">
                        <div class="flex flex-col col-span-12">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.appointment-type"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-appointment-type-selector
                                on-select.bind="handleTypeSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                value.two-way="appointment.type.id"
                                valid.bind="validation.type"
                                t="[placeholder]translation:partial-views.appointments.placeholders.select-appointment-type">
                            </bx-appointment-type-selector>
                        </div>
                        <div class="flex flex-col col-span-6">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.patient"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-patient-selector
                                on-select.bind="handlePatientSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                value.two-way="appointment.patient.id"
                                valid.bind="validation.patient"
                                on-patient-created.bind="createPatient"
                                allow-new-patient.bind="true"
                                t="[placeholder]translation:partial-views.appointments.placeholders.select-patient">
                            </bx-patient-selector>
                        </div>
                        <div class="flex flex-col col-span-6">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.practitioner"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-user-selector
                                on-select.bind="handleUserSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                valid.bind="validation.practitioner"
                                value.two-way="appointment.practitioner.id"
                                t="[placeholder]translation:global.placeholders.select-user;">
                            </bx-user-selector>
                        </div>
                        <div class="flex flex-col col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.appointments.labels.date"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-datepicker value.to-view="appointment.start" onselect.trigger="handleDateChanged($event)" valid.bind="validation.date" placeholder.bind="placeholder"></ux-datepicker>
                        </div>
                        <div class="col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.start"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-input
                                component.ref="startInput"
                                mask="00:00"
                                select-text-on-focus.bind="true"
                                oninput.trigger="handleTimeInput($event)"
                                data.bind="'start'"
                                value.two-way="start"
                                valid.bind="validation.start"
                                t="[placeholder]translation:global.labels.start">
                            </ux-input>
                        </div>
                        <div class="col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.end"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-input
                                component.ref="endInput"
                                mask="00:00"
                                select-text-on-focus.bind="true"
                                oninput.trigger="handleTimeInput($event)"
                                data.bind="'end'"
                                value.two-way="end"
                                valid.bind="validation.end"
                                t="[placeholder]translation:global.labels.end">
                            </ux-input>
                        </div>
                        <div class="col-span-12" if.bind="!appointment.createOutsideSchedule && appointment.practitioner">
                            <ux-alert color="info" if.bind="appointment.schedule">
                                <div class="flex items-center justify-between">
                                    <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.appointments.messages.searching-schedules"></span>
                                    <div class="flex gap-1" else>
                                        <span class="text-sm" t="translation:partial-views.appointments.labels.schedule"></span>
                                        <span class="text-sm">${appointment.schedule.location.translations | translate:language}</span>
                                    </div>
                                    <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
                                </div>
                            </ux-alert>
                            <ux-alert color="warning" else>
                                <div class="flex items-center justify-between">
                                    <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.appointments.messages.searching-schedules"></span>
                                    <span else class="text-sm" t="translation:partial-views.appointments.messages.no-schedule-found"></span>
                                    <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
                                </div>
                            </ux-alert>
                        </div>
                        <div class="flex items-center justify-between col-span-12">
                            <ux-label>
                                <span t="translation:partial-views.appointments.labels.ignore-schedule"></span>
                            </ux-label>
                            <ux-toggle
                                onchange.trigger="handleIgnoringSchedule($event)"
                                checked.two-way="appointment.createOutsideSchedule"
                                label-active.bind="'translation:global.labels.yes' | t"
                                label-inactive.bind="'translation:global.labels.no' | t"
                                size="md"
                                color="warning">
                            </ux-toggle>
                        </div>
                        <div class="flex flex-col col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.appointments.labels.location"></span>
                            </ux-label>
                            <bx-practice-location-selector
                                component.ref="locationSelector"
                                disabled.bind="!appointment.createOutsideSchedule"
                                on-select.bind="handleLocationSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]translation:partial-views.appointments.labels.location"
                                valid.bind="validation.location"
                                value.bind="appointment.location.id">
                            </bx-practice-location-selector>
                        </div>
                        <div class="flex flex-col col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.appointments.labels.room"></span>
                            </ux-label>
                            <ux-select
                                disabled.bind="!appointment.createOutsideSchedule || (appointment.createOutsideSchedule && !appointment.location)"
                                component.ref="roomSelector"
                                valid.bind="validation.room"
                                onselect.trigger="handleRoomSelected($event)"
                                t="[placeholder]translation:partial-views.appointments.placeholders.select-room"
                                value.to-view="appointment.examinationRoom.id">
                                <ux-select-option repeat.for="room of appointment.location.rooms" value.bind="room.id"> ${ room.name | translate:language } </ux-select-option>
                            </ux-select>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="sticky bottom-0 z-[999] flex justify-end gap-x-2 border-t border-gray-200 bg-white px-8 py-4">
                <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                    <span class="pointer-events-none" t="global.buttons.cancel"></span>
                </ux-button>
                <ux-button
                    disabled.bind="!appointment.schedule && !appointment.createOutsideSchedule"
                    size="sm"
                    color="success"
                    disabled.bind="isLoading"
                    loading.bind="isLoading"
                    click.trigger="save()"
                    t="[text-when-loading]translation:global.labels.loading-states.default">
                    <span class="pointer-events-none" t="global.buttons.save"></span>
                </ux-button>
            </div>
        </div>
    </section>
</template>
